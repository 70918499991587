/*
	LESS Stylesheet: Layout Basis
	
	GSIA / © 2014 STURM UND BRÄM GMBH (www.sturmundbraem.ch)
	
	v0.1	05.07.11 Erste Version
	v0.2  07.07.11 Pagenav eingesetzt
	v0.3	18.09.12 Anpassungen für Dialog Integration
	v0.4  07.05.13 Anpassungen an JP Accart
	v0.5  25.06.13 Anpassungen an Fairness at work
	v0.6	12.09.13 Anpassungen an sogas
	v0.7	20.05.14 Anpassungen an GSIA
	
-------------------------------------------------------------------------------*/

/* START MEDIA */
@media screen {
	


html {
	height:100%;
}

/* Typekit FOUT */
.wf-loading {
  /* styles to use before Typekit is loaded */
 h1, h2, h3, h4, p, ul {
 	visibility:hidden;
 }

}
 
.wf-active, .wf-inactive {
  /* styles to use after Typekit is loaded */
  h1, h2, h3, h4, p, ul {
  	visibility:visible;
  }
}

.svg {
	.svg-alt {
		.invisible;
	}
}

/* IE Hack (Anzeige SVGs) */
object {width: 100%}


/**************************** BODY *****************************************/
body {
	margin:0;
	background-color:@bgcolor;
	.base-textformat(@fontsize-base * 1);
	height:100%;
	z-index:0;
}

/* general ul style */
ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

/* Contentpane für Modalen-Dialog */
.contentpane {
	padding:1em;
}

/* Overlay */
.overlay {
 	position:fixed;
	display:none; 
	z-index:400;
	top:0;
	left:0;

   /* color with alpha transparency */
   background-color: rgba(0, 0, 0, 0.4);

    /* stretch to screen edges */
	width:100%;
   height:100%;
}

#main-header {
	#main-logo {
		a {
			color:@logo-color;
			text-decoration: none;
			.icon-sogas-logo {
				font-size:@fontsize-base * 7.5;
			}
		}
		
		// svg {
		// 	shape-rendering: geometricPrecision;
		// 	};
	}
}

/* Workaround, dass SVG im <object> klickbar ist */
#main-logo, #address-icon {
	a { display: block;
		z-index: 1;
	}
	object {
		pointer-events: none;
		z-index: -1;
	}
}
// siehe http://stackoverflow.com/questions/11374059/make-an-html-svg-object-also-a-clickable-link/25165194#25165194


.dividerlines {
	box-sizing: content-box; 
	height: 2px;
	border-top: 3px @black solid;
	border-bottom: 1px @black solid;
}

/* meta nav inlinen */
#metanav {
	.idlanguage {
		display: inline-block;
	}
	.mod-languages {
		display: inline;
		ul {
			display: inline-block;
		}
	}

	// span:last-of-type {
	// 	display: block;
	// }
}



/**************************** Article *****************************************/
article {
	width:@breite-article;
}

.clear {
	clear:both;
	float:none;
}

/**************************** ASIDE / TEASER *****************************************/

///////////////////////////// FRONTPAGE

.aside-frontpage {
	width: 100%;
	.base-fontsans;
	font-size:@fontsize-base * 0.95;
	line-height: @content-lineheight - 15%;

	a {
		color:@content-color;
		text-decoration: none;

		&:link, &:visited {
			//color: #525252;
		}
		
		&:hover, &:focus, &:active {
			//color: #525252;
			.underline {
				.underline_mix();
			}
		}

	}

	.teaser {
		margin: 0 0 2em 0;
		img {
		// margin: 3px 1em 0 0;
		}
		figure {
			margin: 0;
		}
	}
	.summary {
		display: table;

		h3, h2 {
			color: @header3-color;
			font-size: 1.17em;
			margin: 0 0 0.2em 0;
			line-height: @content-lineheight - 20%;
			a {
				color: @header3-color;
			}
		}
		p {
			margin: 0 0 0 0;
		}
	}
}


///////////////////////////// ALL PAGES


/**************************** Footer *****************************************/

.footer-container {

	.base-fontsans;
	font-size:@fontsize-base * 0.9;
	line-height: @content-lineheight - 20%;

	a {
		color:@content-color;

		&:link, &:visited {
			color:@content-color;
			// text-decoration:none;
		}
		
		&:hover, &:active {
			color:@content-color;
			// background-color: @footer-color;
			text-decoration:underline;
		}	

	}

}

/* Sitemap */
#footer-sitemap {
	line-height: @content-lineheight - 0%;

	ul.menu {
		> li {
			.base-fontsansbold;

			> a {
				&:hover, &:active {
					text-decoration:none;
				}
			}

			 ul {
			 	li {
			 		.base-fontsans;
			 		&.active {
						> a {
							text-decoration: underline;
						}
					}			
			 	}
			}
		}
	}
}


/* Logo im Footer kriegt per Javascript die Klasse .footer-logo (index.php) */
.footer-logo {
	g {
		fill:@content-color !important;
		// &.logo-text {
		// 	display: none !important;
		// }
	}
}

/* SVG Logo wird auf Logo-Icon beschnitten (Klasse wird per Javascript gesetzt) */
.logo-text-footer {
	display: none;
}

/**************************** Hilfselemente ******************************/
.hidden, .chronoform {
	display: block;
    height: 0;
    left: -1000px;
    overflow: hidden;
    position: absolute;
    top: -1000px;
    width: 0;
}

#content-pagenav {
	margin:2em 0em 2em 0em;
	a {
		font-size:@fontsize-base * 1;
		margin-right:1em;
	}
}


} /* FIN MEDIA */
