/*
	LESS Stylesheet: Variabeln EXTENDED
	
	sga / © 2014 STURM UND BRÄM GMBH
	
	v0.1	28.8.2014 Variabeln angelegt

 -------------------------------------------------------------------------------*/

/* --------- Variabeln ------------- */

/* Live Site Template URL */
/* CHANGE BEFORE GO-LIVE! CHECK CHECK CHECK! */
/* NO TRAILING SLASH! */
@LiveSiteTemplateUrl:'http://sga-cms.laborplatz.ch/templates/sga';


/* Farben */

@black:#000;
@weiss:#FFF;
@aktiv-color:#e30613; //#5B8CB8; Gsia blau
@inaktiv-color:lighten(@logo-color, 40%);
@logo-color:#e30613;
@content-color:#525252;
@content-box-color:#666;
@bgcolor:@weiss;
@okay-color:#C4C634;
@hint-color:#44A0D6;
@ghosted-color:#BABABA;
@box-bgnd-color:#F2F2F2;
@box-border-color:#CCC;
@infobox-color:@inaktiv-color; //#D2EFFF; Gsia hellblau
@input-border-color:lighten(@logo-color, 45%);
@input-button-color:@logo-color;
@input-bgnd-color:lighten(@content-color, 50%);
@footer-color:@weiss;
@header1-color:#333;
@header2-color:#333;
@header3-color:#333;
@infobox-header-color:@black;
@infobox-content-color:@black;
@boxdefault-color:@content-color;
@boxerror-color:@aktiv-color;
@boxhint-color:@hint-color;
@boxokay-color:@okay-color;
@link-aktiv:@aktiv-color;
@link-hover:@inaktiv-color;
@error-color:#D00;
@text-outline:@black;
@deco-border-color:@content-color + #444;
@light-content-color:@content-color + #333;



/* Abstände */
@header-topmargin:0.5em;
@header-bottommargin:0.5em;
@content-lineheight:140%;
@breite-article:100%;


/* Raster für Inhaltselemente */
@form-label:35%;
@form-input:65%;
@form-spacer:2%;
@form-width-full:60%;
@form-width-small:20%;

/* Rastereinheiten in Pixel */
@grid:128px;
@gutter:11px;
@layout-width:1101px; /* Gesamtbreite des Layouts */
@footer-content-width:1101px; /* Breite des inhaltlichen Bereichs im Footer */
@content-width:5 * @grid + 4 * @gutter; /* Breite des Inhaltbereichs */
@inner-content-width:@content-width; /* Breite des inneren Inhaltbereiches */
@infocol-width:372px; /* Breite der Infospalte */
@frontpage-width:940px; /*Breite Frontpage / Landingpage */
@menu-width:740px;


@width-content:62.42424242424242%; /* 618px / 990px */
@width-addon:100% - @width-content - @gutter-full;
@fullwidth: 100%; /* Volle Breite */
@grid-full: 100%  * @grid / @layout-width; /* Raster für das gesamte Layout */
@gutter-full: 100% * @gutter / @layout-width; /* Gutter für das gesamte Layout */ 
@grid-menu: 100% * @grid / @menu-width; /* Raster für das Menü */
@gutter-menu: 100% * @gutter / @menu-width; /* Gutter für das Menü */
@grid-content: 100% * @grid / @inner-content-width; /* grid für den inneren bereich des inhaltes */
@gutter-content: 100% * @gutter / @inner-content-width; /* gutter für den inneren bereich des inhaltes */
@gutter-footer-content: 100% * @gutter / @footer-content-width; /* Gutter für den Inhaltsbereich im Footer */
@grid-footer-content: 100% * @grid / @footer-content-width; /* Grid für den Inhaltsbereich im Footer */

@grid-frontpage: 7.095046854082999%; /* 53px / 747px */
@gutter-frontpage: 1.338688085676037%; /* 10px / 747px */


@grid-3col: 21.90082644628099%; /* 53px / 242px */
@gutter-3col: 4.132231404958678%; /* 10px / 242px */

@grid-content-normal:100%;

// Frontpage-Aside in SGA
@width-frontpage-aside: 3 * @grid + 2 * @gutter; // Breite des Aside Elementes
@width-frontpage-aside-full: 100% * @width-frontpage-aside / @layout-width; // Breite des Aside Elementes
@grid-frontpage-aside-full: 100% * @grid / @width-frontpage-aside; /* grid für den inneren bereich des Frontpage Aside Elements */
@gutter-frontpage-aside-full: 100% * @gutter / @width-frontpage-aside; /* gutter für den inneren bereich des Frontpage Aside Elements */



/* Footer Sitemap Flexbox in SGA */
@footer-flexbox-width: 4 * @grid + 3 * @gutter;
@grid-footer-flexbox: 100% * @grid / @footer-flexbox-width; /* grid für den inneren bereich der flexbox */
@gutter-footer-flexbox: 100% * @gutter / @footer-flexbox-width; /* gutter für den inneren bereich der flexbox */

/* Aussen Randgrösse in Pages im Layout_wide in SGA */
@aussenabstand: 2.5%;






/* Schrift */ 
@fontsize-base:1em;
@font-family:"sga";


@font-normal:  "skolar", Georgia, "Times New Roman", Times, serif;
@font-light:  "skolar", Georgia, "Times New Roman", Times, serif;
@font-bold: "skolar", Georgia, "Times New Roman", Times, serif;
@font-heavy:  "skolar", Georgia, "Times New Roman", Times, serif;
@font-default: Georgia, "Times New Roman", Times, serif;
@font-sans:	"open-sans", Arial, Helvetica, sans-serif;



/* Small-Screen Dimensionen */
@smallscreen-left:40%;
@smallscreen-right:60%;
