/*
	LESS Stylesheet: Layout Medium
	
	SGA / © 2014 STURM UND BRÄM GMBH

	Die Hauptgestaltung liegt im layout-wide.less.
	Hier können Korrekturen für mittlere Bildschirme angebracht werden.

	Achtung, es hat mehrere Mediaqueries im Dokument !!!
	
	v0.1	06.11.14 Erste Version
	
-------------------------------------------------------------------------------*/

/* START MEDIA */
@media screen and (min-width: 834px) and (max-width: 1101px) {
	

	#aside-container {
		float: none;
		width: 100%;
		margin-left: 0;
		margin-top: 2.5em;
	}


	.footer-container {

		#footer-sitemap {
			float: left;
			margin-left: @aussenabstand;
			width: 70%;
		}

		#metanav-footer {
			width: 2 * @grid-full;
		}

		#sga-address {

			width: 60%;
			clear: both;
			float:none;
			//font-size: 0.9em;

			#address-icon {
			margin: 0px 10% 0em 0em;
			width: 50%;
			float: left;
			}

			#address-info {
			margin: 0;
			//font-size: 0.9em;
			line-height: 100%;
			float: left;
			width: 40%;
			}
		}
	}

	/****** Image Gallery **************/

	.image_gallery {
		.image-gallery-mixin(7,2.66%);
	}


}
/* FIN MEDIA */


/* START MEDIA FINETUNING */

@media screen and (min-width: 834px) and (max-width: 1201px) {

	.bodyfrontpage #content-container article#content {
		width: 4.5 * @grid-full + 4 * @gutter-full;
		float: left;
		margin-right: 0.5 * @grid-full + 1 * @gutter-full;
	}
}

@media screen and (min-width: 834px) and (max-width: 1025px) {

	#menu-wrapper .top-menu>ul>li {
		font-size:@fontsize-base * 1.4; // Anpassung Menuschrift
	}
}

@media screen and (min-width: 834px) and (max-width: 950px) {

	#menu-wrapper .top-menu>ul>li {
		font-size:@fontsize-base * 1.3; // Anpassung Menuschrift
	}
}

@media screen and (min-width: 834px) and (max-width: 880px) {

	#menu-wrapper .top-menu>ul>li {
		font-size:@fontsize-base * 1.2; // Anpassung Menuschrift
	}
}


/* START MEDIA FINETUNING */
