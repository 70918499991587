/*
	LESS Stylesheet: Layout General
	
	SGA / © 2014 STURM UND BRÄM GMBH
	
	v0.1	23.10.14 Erste Version

-------------------------------------------------------------------------------*/

/*------------------ GENERAL STYLES FOR ALL MEDIA ------------------*/


/*------------------------- METANAV MEMBER LOGIN */

#metanav {

	.divider {
		display: block;
	}
	.css-arrow-down {
		
	}
	.dropdown {
		display: none;
		position: absolute;
		right: 0px;
		z-index: 70;
		background-color: lighten(@black, 95%);
		background-color: white;
		border-style: solid;
		border-color: lighten(@black, 50%);
		border-width: 0 1px 1px;
		// border-top: 0;
		top: 3.3em;
		padding: 1em 0.5em 0.5em;
		box-sizing: border-box;
		// color: @black;
		width: 400px;
		.base-fontsans;
		
		form {
			margin-top: 0;
			.base-fontsans;

			.row {
				div.checkbox label.checkbox.medium {
					width: @form-width-full - 5%;
					margin-top: 2px;
				}

				button, label {
					.base-fontsans;	
					font-size: 0.95em !important;
				}

			}

		}
		#form-login-username {
			padding-top: 1em;
		}
		.row {
			&:first-of-type {
				margin-top: 0;
			}
		}
	}
}



/*------------------------- INITIALE */

.initiale > p:first-of-type {

	&:first-letter {
		float:left;
		font-size: @fontsize-base * 3.5;
		line-height: @content-lineheight - 60%;
		padding-right:11px;
		color: lighten(@black, 20%);
	}
}



/*------------------------- TEASER FRONT */
.aside-frontpage {
	.hideteaser {
		.hidden;
	}
}



/*------------------------- FACHGREMIEN */

.gremium-creds p {
	.base-fontsans;
	font-size:@fontsize-base * 0.8;
	margin-bottom: 0em;
	&:last-of-type {
		margin-bottom: 1em;
	}
}




/*------------------------- SUCHRESULTATE */

.result-title {
	a {
		font-weight: 600;
	}
}

.result-text {
	margin-bottom: 0.8em;
	margin-left: 0;
}

article #searchForm {
	margin-top: 2em;
	label {
		margin-top: 7px;
		// hide it
		.visuallyhidden;
	}
	button {
		margin-left: 0;
	}
}

.searchintro {
	margin-bottom: 2em;
	margin-top: 2.5em;
}

.search-results {
	.highlight {
	background-color: @input-border-color;
	padding: 0 4px;
	}
}



/*------------------------- ARTICLE GESTALTUNG LISTEN */

article ol {
	width: 70%;
}


/*------------------------- VORSTANDSMITGLIEDER */

.page-vorstand {
	.initiale {
		// margin-bottom: 3em;
		> p {
			&:first-of-type:first-letter {
				// float:left;
				// font-size: @fontsize-base * 3.5;
				// line-height: @content-lineheight - 60%;
				// padding-right:11px;
				// color: lighten(@black, 20%);
			}
		}
	}
	.items-leading {
		&:after {
			display: none !important;
		}
	}
	.column-1 {
		.vorstandsmitglied {
			margin-right: 10%;
			//background-color: red;
		}
	}
}



.vorstandsmitglied {
	//width: 2 * @grid-content + 1 * @gutter-content;
	//min-width: 255px;
	float: left;
	// margin-right: 0.5 * @grid-content + 1 * @gutter-content;
	margin-bottom: 2em;
	width: 45%;

	figure {
		// width: 267px;
		margin: 0 0 0.8em 0;
		// &.hochformat {
		// 	height: 178px;
		// 	overflow: hidden;
		// 	img {
		// 		position: relative;
		// 		margin-top: -20%;
		// 	}
		// }
	}
	img {
		// border: 1px solid black;
		margin: 0;
	}

	h2 {
		margin: 0.5em 0em 0.3em 0em;
	}
	p {
		margin-bottom: 0;
	}
	.vorstandsfunktion {
		.base-fontsans;
		font-size: @fontsize-base * 0.8;
	}
}

/*------------------------- DOCUMENTS */

/* Documents im Text */
.info, .document-infos {
		font-family: "open-sans"; 
		font-size: 0.7em;
	}

.document-item {
	margin-left: 1.5em;
	.icon-file-pdf {
		margin-left: -1.5em;
	}
}

/* Documents im Aside */
#aside-container {
	margin: 1em 0 1.5em 0;
	border-top: 1px solid @content-color;
	border-bottom: 1px solid @content-color;
	padding-bottom: 1em;
	.base-fontsans;
	line-height: @content-lineheight - 20%;
	font-size:@fontsize-base * 0.8;
	h1, h2 {
		.base-fontsansbold;
		font-size:@fontsize-base * 1.1;
		&:first-of-type {
		margin-top: 1em;
		}
	}

	p { 
		.base-fontsans;
		margin-bottom: 0;
	}
	.document-infos {
		font-size: 0.85em;
	}

	// Abstände der einzelnen Items im Aside
	.document-item, .item {
		margin-bottom: 0.5em;
	}
}



/*------------------------- TABULIZR xls to html tables */

.excel-table {

	table {
		.stubr_header .tabcol {
			.base-fontsansbold;
			font-size: 0.8em;
		}
		// td.sorting, td.sorting_asc, td.sorting_desc {
		// 	// padding-left: 20px;
		// 	// background-position: 0px 3px;
		// 	// &:hover {
		// 	// 	cursor: pointer;
		// 	// }
		// }
		&.tabtable-rs_stubr_default {
			font-size: 0.8em;
		}
	}


	input {
	.base-fontlight;
	.input-border;
	
	&:focus {
		.input-focus;
		}
	}

	div.tab_top_right {
	width: 100%;
	margin-top: 1em;
	margin-bottom: 2em;
	}
	thead {
		.base-fontsansbold;
		font-size: 1.1em;
	}
}


/*------------------------- Icons */
.icon-star:before {
	font-size: 0.6em;
	vertical-align:text-top;
}

/*------------------------- Login */
.pwvergessen {
	margin-top: 1em;
}



/*------------------------- NEWS UND EVENTS allg. */

// allgemeines, das für News und Events gilt
.news, .event {
	.event-date, .event-location {
		.base-fontsans;
		font-size: 0.9em;
	}
}


/*------------------------- Events */

// in Frontpage Liste und in Veranstaltungen Blog Liste
.event.introview {
	img {
		width: auto;
		margin-left: 0;
		margin-right: 1em;
		float: left;
	}
}

// nur in Veranstaltungen Detailansicht
.event-blogview {
	// Der Titel müsste hier angesprochen werden, dann aber unten für die Listenansicht wieder überschreiben!
	.event.fullview {
		.event-label {
			display: block;
			font-weight: 600;
		}
		.event-date, .event-location, .event-organisator {
			font-size: 0.8em;
			.base-fontsans;

		}
	}
}

// nur in Veranstaltungen Listenansicht
.event-blogview {
	.event.introview {
		// Der Titel wird hier angesprochen werden
		h2 {
			margin-top: 0;
		}
		figure {
			margin: 0;
		}

	}
}

// Kategorie Vorschau
.event-vorschau {

}

// Kategorie Rückschau
.event-rueckschau {
	.event-googlemap {
		display: none;
	}
}


/*------------------------- News / Aktuelles / Veranstaltungen */

// allgemein, für Intro- und Pageview und Frontpage
.news {
	
}


// in Frontpage Liste und in Aktuelles Blog Liste
.news.introview {

}
// Page-view
.item-page.aktuelles, .item-page.event-blogview {
	.page-header {
		h1 {
			.base-textformat(1em);
			color: lighten(@content-color, 30%);
		}
	}
	p.credits {
		font-size: 0.85em;
		margin-bottom: 0.8em;
	}
}

// .aktuelles für Intro- und Pageview
.aktuelles, .event-blogview {

	.items-row {
		margin: 0 0 2em 0;
	}

	// Readmore Link (geht über ganzes Intro)
	a.btn {
		&:hover, &:focus, &:active  {
			text-decoration: none;
			h2 .underline {
				.underline_mix(5px,6px,white);
			}
			.readmore .underline {
				.underline_mix();
			}
		}
		figure {
			margin-bottom: 0;
			margin-top: 0;
		}
		.summary {
			display: table;
			p.credits {
				font-size: 0.85em;
				margin-bottom: 0.5em;
			}
			p:last-of-type {
				margin-bottom: 0;
			}
		}
		.readmore {
			margin: 0;
		}
	}

	// nur für Introview
	.introview {
		margin-top: 1em;

		figure {
			// position: relative;
			// width: 128px;
			// padding-bottom: 128px;
			// float: left;
			// margin: 3px 1em 1em 0;

			img {
				// position: absolute;
				// width: auto;
				// max-width: 100%;
				// height: auto;
				// max-height: 100%;
				// margin: 0;

				width: 20%;
				max-width: 128px;
				margin: 0 1em 1em 0;
				float: left;
			}
		}
		h2 {
			margin-top: 0;
			margin-bottom: 0.4em;
		}
	}
}

// Nur für Introview der Veranstaltungen
.event-blogview a.btn .introview .summary {
	.credits {
		margin-bottom: 0;
	}
	p ~ h2 {
		margin-top: 0.5em;
	}
}



/*------------------------- Image Gallery */

.image_gallery {
		.image-gallery-mixin;
}




/*------------------------- Footer Logo */
#sga-address {
	p {
		margin: 0;
	}
	#address-icon {
		margin: 25px 0em 1em 0em;

	}
	#address-info {
		.address-info-firma {
			.base-fontsansbold;
		}
		.address-info-ort {
			margin-bottom: 1em;
		}
		.address-info-email {

		}
	}
}




/* FOOTER-METANAV */
#metanav-footer {
	.base-fontsansbold;
	line-height: @content-lineheight + 0%;
	margin-top: 25px;
	margin-bottom: 2.5em;

	ul {
		li {
			margin: 0;
		}
	}

	ul li, span[class^=item] {
		margin-bottom: 10px;
	}

	span[class^=item] {
		display: block;
	}
}
/* ENDE FOOTER-METANAV */


