/*
	LESS Stylesheet: Content
	
	GSIA / © 2014 STURM UND BRÄM GMBH (www.sturmundbraem.ch)

	
	v0.1	01.07.11	 Erste Version
	v0.2	05.07.11 	Inhaltselemente eingefügt
	v0.3	06.07.11	Farben und Inhaltselemente definiert
	v0.4	07.07.11	FontSquirrel eingebaut, Weitere Inhaltselemente eingesetzt
	v0.5	12.07.11	@import für Variabeln
	v0.6	05.08.11 	Anpassung Formular-Abstände
	v0.7	23.08.11	Boxen-Abstände gesetzt (.infobox) und neue Box eingeführt: links
	v0.8	06.09.11	Anpassungen der Icon-paddings (Fehler in padding-right)
	v0.9  	31.05.12	Anpassungen an RAL
	v1.0	11.09.12 	Anpassungen an Dialog Integration
	v1.1	15.03.13	Anpassungen an JP Accart
	v1.2  	25.06.13	Anpassungen an fairness at work
	v1.3	22.11.13	Anpassungen an sogas
	v1.4	10.05.14	Anpassungen an GSIA
-------------------------------------------------------------------------------*/



/* Definition Links */
a {
	&:link, &:visited {
		color:@link-aktiv;
		text-decoration:none;
	}
	
	&:hover, &:active {
		color:@link-aktiv;
		text-decoration:underline;
	}	
	
	i {
		text-decoration:none !important;
	}
}




/**************************** Content Elements *****************************************/


/* Alles Inhaltsformatierungen */
article {
	.hyphenation();
	margin-top:0em;

	.base-textformat(@fontsize-base * 1.2);

		
	h1 {
		.base-fontheavy;
		font-size:@fontsize-base*2;
		// margin:1em 0em 1.1em 0em;
		margin:2em 0em 0.5em 0em;
		color:@header1-color;
		line-height: @content-lineheight - 40%;

		&:first-of-type {
			margin-top: 0;
		}
		
		&.front {
			font-size:@fontsize-base*2;
		}

		a {
			&:link, &:visited, &:hover, &:active {
				color:@header1-color;
			}
		}
	}
	
	h2 {
		.base-fontbold();
		font-size:@fontsize-base*1.2;
		line-height: @content-lineheight - 40%;
		margin:2em 0em 0.5em 0em;
		color:@header2-color;
	
		&.front {
			margin:3em 0em 1em 0em;
			font-size:@fontsize-base*1.8;
		}

		a {
			&:link, &:visited, &:hover, &:active {
				color:@header2-color;
			}
		}
	}

	
	h3 {
		.base-fontbold();
		line-height:@content-lineheight - 40%;
		font-size:@fontsize-base*1.2;
		margin:2em 0em 0.5em 0em;
		color:@header3-color;

		a {
			&:link, &:visited, &:hover, &:active {
				color:@header3-color;
			}
		}
	}
	
	h4 {
		.base-fontdefault();
		font-size:@fontsize-base*1;
		line-height:@content-lineheight - 40%;
		margin:1em 0em 0.5em 0em;
		color:@header3-color;
	}
	
	
	p {

		.base-textformat();
		margin:0 0em 1em 0em;
		line-height:@content-lineheight + 5%;
	
		&.small {
			font-size:@fontsize-base * 0.8;
			line-height:@content-lineheight - 20%;
		}

		&.strong {
			font-weight: normal;
		}
	}


	/* Lead */
	p.introduction {
		margin-bottom:1em;
		.base-fontnormal;
		
			font-size:@fontsize-base*1.1;
			line-height: @content-lineheight - 20%;
		
	}

	/* Haupttext */
	.page-main  {
		h2:nth-child(1) {
			margin-top:0 !important;
		}
		
	}
	/* Ende Haupttext */
	
	/* Fehlermeldungen / Hinweise */
	.message, .box {	
		.box-default(@box-border-color);	
		line-height: @content-lineheight - 50%;

		

		.box-wrapper {
			margin:1em;
		}

		
		
		h2 {
			font-size:@fontsize-base * 1.2;
			margin:0em 0em 0em 0em;
		}
		
		/* Fehler */
		&.error {
			.box-default(@boxerror-color);
			
			.icon-large {
				color:@error-color;
			}
			
		}
		
		/* Hinweis */
		&.hint {
			.content-box;

			.icon-info-sign {
				display:block;
				font-size:@fontsize-base * 3;
				float:left;
				width:15%;

			}

		}
		
		/* Erfolgreich */
		&.okay {
			.box-default(@boxokay-color);
			
			.icon-large {
				color:@okay-color;
			}
		}

		&.id-more-pages {
			.content-box;
			
			h3 {
				margin:0.5em 0em 0.5em 0.5em;
				span {
					margin-right:10px;
					font-size:@fontsize-base * 1.5;
					vertical-align: text-bottom;
				}
			}
			nav {
				margin:0.5em;
			}
			p {
				font-size:@fontsize-base * 0.9;
				float:left;
				margin-right:0%;
				width:45%;
				line-height: @content-lineheight - 50%;
				text-indent: -1em;
				margin-left:5%;

				.icon-arrow-right {
					font-size:@fontsize-base * 0.8;
					margin-right:5px;

				}
			}

		}

		
		&.download {
			.content-box;

			.icon-file-pdf:before {
				content: "\e003";
			}
			
			h2 {
				text-transform: uppercase;
				margin:0em 0em 1em 0em;
				span {
					margin-right:10px;
					font-size:@fontsize-base * 1.5;
					vertical-align: text-bottom;
				}
			}
			p, ul {
				font-size:@fontsize-base * 0.8;
			}
			
			ul {
				margin:0em 0em 0em 0em;
			}
			li {
				line-height: @content-lineheight - 50%;
			}
		}
		
		&.copyright {
			.content-box();
			padding:0em 2em 0em 5em;
			float:none;
			clear:both;
			

		
			
		}

	}
	
	address {
		.base-textformat();
		margin:0em 0em 1.5em 0em;
		font-style: normal;
	
		line-height: @content-lineheight - 0% !important;
	}

	/* System-Messages Joomla */
	#system-message-container {


		ul {
			margin:0;
			padding:0;
			li {
				margin:0;
				padding:0;
				width:auto;
			}
		}
	
		dl {
			overflow:hidden;
			dt {
				margin:0;
			}
			dd {
				margin:0;
			}
		}
	}
	
}



/************** Bildcontainer & Legende *******************/


article {

	figure {
	  	margin: 1.5em 0 2em;
	}

	figcaption {
		font-size: 0.8em;
		line-height: 1.3em;
	}
}

article.frontpage {
	figure {
		margin: 0 0 2em 0;

		img {
			margin-bottom: 1.7em;
		}
	}
}


/************** FORMULARE *******************/

form 	{
	font-size:@fontsize-base;
	margin-top:1em;
	.base-fontlight;
	
	p {
		.base-fontlight;
	}
	
	.icon-location {
		color:@error-color;
		font-size:70%;
		vertical-align: super;
	}

	.message {
		&.error {
			margin-left:0;
			width:auto;
		}
	}

	&[id^=chronoform_] {
		.row {
			img {
				clear:both;
				float:none;
				width: auto; /* schaltet allg. w100% wieder aus für captcha-bild etc. */
			}
		}
	}

	/* Button in Bootstrap needs some treatment */
	.btn {
		.base-fontlight;
		text-align: left;
		font-size:1em !important;	
	}

	.chzn-container {
		font-size:0.9em;

		.chzn-search {
			input {
				float:none;

				&:focus {
					color:@content-color;
				}
			}
		}
	}

	.chzn-choices, .chzn-results {
		li {
			&:before {
				content:"" !important;
			}	
		}
	}

	.btn-danger {
		display:none;
	}

	.btn-success {
		font-size:1rem !important;	
	}

	#jform_birthdate {
		width:40%;
		float:none;
		padding:6px !important;
		font-size:14px !important;
	}





	.row {
		margin:1em 0em 1em 0em;
		max-width:100%;
		

		&.clearfix {
			clear:both;
			float:none;
		}


		
		label {
			display:block;
			line-height:@content-lineheight - 40%;
			float:left;
			width:@form-label - @form-spacer;
			text-align: right;
			margin-right:@form-spacer;
			font-size:1em !important;
		
			
			&.checkbox {
				max-width:90%;
				margin-right:10px;
				float:left;
			}

		}
		
		&.no-label {
			
		}
		
		textarea, select {
			.input-border;
			.base-fontlight;
			float:left;
			width:@form-input;
			font-size: 1em !important;
			
			
			&.full {
				width:@form-width-full - 3%;
				height:5em;
			}
			
			&:focus {
				.input-focus;
			}
		}
		select.full {
			height:auto;
			width:@form-width-full;
		}
		
		.text-large {
			input {
				width:@form-width-full - 2%;
			}
		}
		
		input, button {
			.base-fontlight;
			.input-border;
			float:left;
			width:@form-input;
			text-align: left;
			font-size:1em !important;	
			
			&.text {
				
			}
			
			&.medium {
				width:@form-width-full / 1.2;
			}
			
			&.large {
				width:@form-width-full - 2%;
			}
			
			&.small {
				width:@form-width-small;
			}
			
			&.checkbox {
				display: inline-block;
    			float:left;
    			margin-right:5px;
    			margin-top:2px;
			}
			
			
			&.submit, &.button, &[class^="-button"], &[class*=" -button"] {
				color:@weiss;
				border:none;
				background-image: none;
				background-color: lighten(@content-color, 20%);
				width:auto;
				margin-left:@form-label;	
				&:hover, &:active, &:focus {
					background-color: darken(@content-color, 10%);
				}
				
			}

			&.submit {
			//	background-color: @okay-color;
			}

			&.backstep {
				background-color: @inaktiv-color;
			}
			&.nextstep {
				background-color: @logo-color;
			}


			&.chrono_captcha_input {
				width:@form-width-small;
			}
			
			&:focus {
				.input-focus;
			}
			
			
		}
		
		button {
			.input-button;

			&.inline {
				margin:0px 4px 0px 4px;
			}

		}
		
		
		&.inline {
			max-width:100%;
			
			label {
				float:left;
				&.checkbox {
					width:auto;
				}
			}
			
			input {
				margin-left:0%;
				&.checkbox {
					width:1em;
				}
			}
		}
		
		div.checkbox {
			width:100%;

			input.checkbox {
				width:1em;
				margin-left:@form-label;
			}
			
			label.checkbox {
				text-align:left;
				float:left;
				width:@form-width-full - 5%;

				&.medium {
					width:@form-width-full / 1.5;
				}
			}
			
			div {
				clear:both;
				float:none;
			}
			
			div.inline {
				float:left;
				margin-left:4%;
				max-width:45%;
				clear:none;
				
				input {
					max-width:100% !important;
				}
			}
		}

		
		.tipp {
			clear:both;
			float:none;
			display:block;
			font-size:@fontsize-base * 0.8;
			color:lighten(@content-color, 10%);
			width:@form-input;
			text-align: left;
			margin-left:@form-label;
		}
		
		.requiredtext {
			color:@error-color;
			font-size:@fontsize-base *1 !important;
			margin:0;
			
			.fc-error {
				// display:inline;
				background:none !important;
				border:none;
				padding: 0;
				margin: 0 0 0.8em;
				//.input-focus;
				// text-align: center;
			
					p {
					display:inline-block;
					font-size:@fontsize-base * 1 !important;
					.base-fontlight;
					border: 1px solid @error-color;
					// border: 1px solid @input-border-color;
					padding: 0.2em 1em;
					width: 58%;
					margin: 0;
					margin-left: 35%;
					text-align: center;
					box-sizing: border-box;
				}	
			}
		}
		
		input {
			&.invalid {
				background-color:lighten(@error-color,30%);
			}
		}

			
		.rows {
			display: inline-block;
    		margin-top: -1px;

    		&.no-clear {
				width:@form-width-full - 3%;

				input {
					margin-left: 0px !important;
				}
			}
		}

		/* Bootstrap needs to be corrected here */

		


	}
	

	/* Bootstrap validating rules */
	.invalid {
		background-color:lighten(@error-color,30%) !important;
		border-style: 1px solid @error-color;
		color:@error-color;
	}
	label {
		&.invalid {
			background-color: #FFF !important;
			color:@error-color;
		}
	}
	
			
}



/****************** BILDER ***********************/
article {

	img {
		max-width:100%;
		width: 100%;
		height:auto;
		// float:left;
		// margin:0em 0 0.5em 0em;
		display: block;
		margin: 0 auto 0.5em;

	}



	/* Lightbox zoom */
	.lightbox-zoom {
		position:relative;
		height:auto;

	}
}




.page-image {
	float:left;
	margin-right:@gutter-content;
	margin-top:0.2em !important;
	margin-bottom:2em;
	width:50%;
	padding:4px;

		
		figcaption {
			clear:both;
			float:none;
			.base-fontlight;
			font-size:@fontsize-base * 1;
			line-height:@content-lineheight - 50% !important;
			margin:0em 0em 1em 0;
			color:@content-color;
			width:90%;



			.icon-enlarge {
				display:block;
				position: relative;
				top:-2em;
				width:1em;
				left:103%;
				.font-shadow();
				color:@aktiv-color;
			}
			
		}
	
	
	img {
			height:auto;
	}
	

}

.item-page-news {
	.page-image {
		width:2 * (@grid-content + @gutter-content) + @grid-content;
	}
}

.blog-publications {
	.page-image {
		width:2 * @grid-content + @gutter-content;
	}
}

/* Fancybox */
.fancybox-title {
	line-height:@content-lineheight - 50% !important;
}


/***************** ENDE BILDER *******************/

/***************** LISTEN ***********************/
article, .idnews {
	ul, ol {

		margin:1em 0em 2em 2em;
		// overflow:hidden;
		list-style-image: none;
		list-style: none;
		padding:0;
		
		li {
			padding-left:0.7em;
			margin:0em 0em 1em 0em;	
		}
		
		li:before {
  			content: "\e61e";
  			font-family: @font-family; /* or whatever */
  			display: inline-block;
  			margin-left: -1.2em; /*same as padding-left set on li*/
  			width: 1.2em; /*same as padding-left set on li*/
  			font-size: 0.6em;
		}

		&.nav {
			li {
				padding:0;
				margin:0;
			}
			li:before {
				content:"";
				margin:0;
				width:auto;
			}
		}	
	

	}

	ol {
		margin:1em 0em 3em 4.2em;
		list-style-position: outside;
		list-style-type:decimal;

		li:before {
			content:'';


		}


	}

	
	
	
	
}

.idnews {
	ul {
		.base-fontsans;
		font-size: @fontsize-base * 0.8;
		line-height: @content-lineheight - 50%;
	}
}

/***************** ENDE LISTEN *******************/

/***************** DOCUMENT DOWNLOADS ***********/
.articledocs {
	margin:2em 0em 2em 1.5em;
	.document-item {
		margin:0em 0em 1em 0em;
		line-height: @content-lineheight - 30%;
	}
}

/***************** ENDE DOCUMENTE DOWNLOADS *****/


/***************** START SUCHRESULTATE ***********/
#search-results {

	a {
		&:hover, &:active {
			text-decoration: underline;
		}
	}

	#form-resultate {
		margin-bottom:5em !important;
	}


	.result-title {
		margin:0;
		width:100%;
	}
	
	.result-text {
		margin:0em 0em 1.5em 0em;
		width:100%;
		line-height:@content-lineheight - 30%;
	}

	#search-query-explained {
		margin:1em 0em 2em 0em;
	}
	
	.highlight {
		background-color:lighten(@aktiv-color,35%);
	}
	
	.search-pagination {
		margin-top:2em;

		ul {
			li {
				list-style-type: none;
				display:inline-block;
			}
		}
	}
}

/***************** ENDE SUCHRESULTATE *************/

/***************** START LINKS ******************/
#link-list {
	a {
		&:hover, &:active {
			text-decoration: underline;
		}
	}


	.row-item {
		margin-top:2em;
		margin-bottom:0;
	}
	
	.item-title {
		margin:0;
	}
}

/***************** ENDE LINKS *******************/

/***************** START AKTUELLES ******************/
.blog-news {


	.row-item {
		margin-top:0em;
		margin-bottom:5em;
	}
	
	.item-title {
		margin:0;
	}
}

/***************** ENDE AKTUELLES *******************/

/***************** TABELLEN *******************/
article {
	table {
		width:100% !important;
		margin:1em 0em 1em 0em;
		
		caption {
			display:none;
		}


		tr {
			border-bottom:1px dotted lighten(@black,40%);

			th, td {
				.base-textformat;
				padding:0.4em 0.4em 0.4em 0.4em;
				text-align: left;
				line-height:@content-lineheight - 30%;
				//font-size:@fontsize-base * 0.85;
				vertical-align: top;
				//color:darken(@content-color,20%);
				
				p {
					margin:0em 0em 0.5em 0em;
					.base-textformat;
					line-height: @content-lineheight - 30%;
					
				}
			}
			
			th {
				padding-bottom:0.2em;
				padding-top:0.5em;
				.base-fontbold;
				img {
					
				}
			}
			td {
				&.stubr-col-s1 {
					width:5%;
					border-left:1px solid @content-color;
				}

				&.stubr-col-w1 {
					width:50%;
				}

				&.stubr-col-wide {
					width:30%;
				}
				&.stubr-col-small {
					width:20%;
				}
				&.sorting, &.sorting_asc, &.sorting_desc {
					// padding:0px 0px 0px 18px;
					// background-position:top left;
					padding-left: 20px;
					background-position: 0px 3px;
					&:hover {
						cursor: pointer;
					}
				}
			}
		
		}

		thead {
			td {
				// padding-bottom:0.2em;
				padding-top:0.5em;
				.base-fontbold;
			}
		}		
		
		
	}
}


/**************** ENDE TABELLEN ***************/


/*************** TEAM PORTRAIT ***********/
.team-portrait {
	float:left;
	margin-right:2%;
	position:relative;
}

.team-info {
	float:left;
}

/************** ENDE TEAM PORTRAIT *******/


/**************** NAV CONTENT CONTEXT **********************/
.idcontent-nav {
	.content-nav-box();
	padding-bottom:2em;

	
	h3 {
		.base-fontbold;
		text-transform: uppercase;
		margin:1em;
		font-size:@fontsize-base * 1;
	}
	
	ul {
		font-size:@fontsize-base * 0.9;
		margin:0%;
		padding:0;
		line-height:@content-lineheight - 40%;
		
		a#active_menu {
			text-decoration:none;
			color:@content-color;
		}
		
		li:before {
			font-family:'sogas';
			content: "\e01d";
		}
		
		li {
				
				font-size:@fontsize-base * 1;
				margin:0px 0px 0.5em 1em;
				
				ul {
					margin-bottom:1em;
					li {
						margin-left:0em;
						margin-bottom:0.3em;
						font-size:@fontsize-base * 1.1;
						
						li {
							font-size:@fontsize-base * 1;
						}
					}	
				}	
			}
	}


}

/**************** ENDE NAV CONTENT CONTEXT **********************/



/***************** START YOUTUBE VIDEO **********************/
.youtube-player {
	max-width:100%;
}

/***************** ENDE YOUTUBE VIDEO ***********************/

/*************** SITEMAP **************************/
article {
	#xmap {
		ul {
			list-style: none;
			margin:0;
			padding:0;
			
			li:before {
				content:'';
			}
		
			li {
				margin:0;
				padding:0;
				a {
					background-image:none;
				}
			}
		
						
									
			&.level_0 {

			
				li {
					.base-fontnormal();
					font-size:@fontsize-base * 1.4;
					margin-bottom:0em !important;
					margin-top:0em;
					
					
					.level_1 {
						margin-top:0em;
						margin-bottom:5em;
						padding-top:0em;
				
						li {
							margin:0em 0em 0.5em 0em;
							display:block;					
							margin-right:5%;
							line-height:@content-lineheight - 50%;
							padding-top:0.5em;
							font-size:@fontsize-base * 0.8;
							.base-fontnormal();
							

							.level_2 {
								margin-bottom:1em;
								li {
									margin:0;
									width:auto;
									clear:both;
									float:none;
									background-image: none;
									font-size:@fontsize-base * 1;
									.base-fontlight;
									
									.level_3 {
										margin-bottom:1.5em;
										li {
											font-size:@fontsize-base * 0.9;
											margin:0em 0em 0em 0.5em;
											li {
												font-size:@fontsize-base * 1;	
											}
										}
									}

								}
							}
						}
					}
				
				}
			}
		
		}
	}
}



/*************** ENDE SITEMAP *********************/

/************** LANDINGPAGE TEASER *********/
.landingpage {
	.blog {
	
		.items-row {
	
			h2 {
				.base-fontbold;
				text-transform: uppercase;
				font-size:@fontsize-base * 1;
				margin:0em 0em 0.5em 0em;
				line-height:@content-lineheight - 50%;
				
			}
			p {
				margin:0em 0em 0.5em 0em;
				line-height:@content-lineheight - 50%;
			}
			a {
				margin:0;
				line-height:@content-lineheight - 60%;
			}
		}
		
	}
}


/*********** ENDE LANDINGPAGE TEASER ******/

/************** FRONTPAGE TEASER *********/
.frontpage {
	.blog {
	
		.items-row {
	
			h3 {
				.base-fontbold;
				font-size:@fontsize-base * 1;
				margin:0em 0em 0.5em 0em;
				line-height:@content-lineheight - 50%;
				
			}
			p {
				.base-fontlight;
				margin:0em 0em 0.5em 0em;
				line-height:@content-lineheight - 50%;
			}
			a {
				margin:0;
				line-height:@content-lineheight - 60%;
			}
		}
		
	}
}


/*********** ENDE FRONTPAGE TEASER ******/


/****************** BLOG ******************/
.blog {
	.items-more {
		h3 {
			margin:0em 0em 1em 0em;
		}
	}
	
	
	.spshare {
		.invisible;
	}
	
	.items-row {
		p {
			// margin:0em 0em 0em 0em;
		}
		
		.readmore {
			margin:1em 0em 0em 0em;
			text-align: right;
		}
	}
	

	
	.item-separator {
		margin:0px 0px 2em 0px;
		clear:both;
		float:none;
	}
}

.blog-nointro {
	h2 {
		margin:0em 0em 0.5em 0em;
	}
}

/************ TESTIMONIALS ****************/
.testimonial {
	&.text {
		width:75%;
		p {
			.base-fontnormal;
			font-size:@fontsize-base * 1.2;
			line-height: @content-lineheight - 30%;
			
			.quote {
				font-size:@fontsize-base * 3;
				color:lighten(@content-color,50%);
				vertical-align: sub;
				line-height: 10%;
				margin-top:0em;
			}
		}
		p.autor {
			.base-textformat;
			font-size:@fontsize-base * 0.8;
		}
		
	}
}

/************** ENDE TESTIMONIALS ********/


/**************** ENDE BLOG ****************/

/************ START FAQ ***************/
.blog-faq {
	.answer {
		margin-bottom:2em;
		
		.icon-bubbles {
			font-size:@fontsize-base * 3;
			float:left;
			display:block;
			color:lighten(@content-color,20%);
			padding-bottom:1em;
			margin-right:1em;
		}
	}
}

/*********** ENDE FAQ ***************/

/************** ERROR MESSAGE EVENTBOOKING ****/
.formError {
	left:50% !important;
}

/************** END ERROR MESSAGE EVENTBOOKING ***/


/************** START BLOG-BILDER ****************/
article {
	.blog-image {
		overflow:hidden;
		position:relative;
		
	}
}

/************** ENDE BLOG-BILDER ****************/

/************* START BOOTSTRAP CORRECTIONS **********/
.dl-horizontal {
	> dd:after {
  		display: table;
  		content: "";
  		clear: both;
  	}
}

/************** END BOOTSTRAP CORRECTIONS ***********/


/************* START TOOLTIP ************/
.tooltip {
	z-index:1000;
	position:relative;
	display:none;
}

/************ ENDE TOOLTIP ************/