/**

	MEGADROPDOWN
	© 2014 STURM UND BRÄM GMBH

	v0.1	11.09.14	Initial Version, excluded from other files

**/





/***************** MAIN NAVIGATION *******************/



/* Top Menu - Obermenu */
#menu-wrapper {
	position:relative;
	height:auto;

	

		// line-height: @content-lineheight - 60%;
		font-size:@fontsize-base * 1.198rem;
		width: @fullwidth;

	

	.top-menu {
		a {
			text-decoration: none;

			&:link, &:visited {
				color: @black;
			}
			
			
			&:hover, &:focus, &:active {
				color: @logo-color;
				// background-color:lighten(@logo-color, 10%);
			}
		}

		> ul, .cbp-hrsub-inner {
			width: 100%;
			margin: 0;
			padding: 0;	

			// > li:last-of-type::after { content: ''; padding-left: 100%; display: inline-block; }

			> li {
				font-size:@fontsize-base * 1.5;
				width:auto;
				float: left;
				margin-right: 2%;
				&:last-of-type {
					margin-right: 0;
				}
				z-index:50;
				.base-fontnormal;				
					
					
				.cbp-hrsub {
					display: block;
					padding-bottom:1em;
				}
			}
		}

		.active > a {
			color: @logo-color;
		}

		/* ausrichtung des menus mittels flexbox */
		> ul {
			display: flex;
			justify-content: space-between;
			direction: ltr;
			align-items: flex-start;
			flex-direction: row;
			> li {
				// flex: 1;
				// margin: 0 auto;
			}
		}

		/* ausrichtung des menus mittels table (gibt nur gleichgrosse cells) */
		// > ul {
		// 	display: table;
		// 	> li {
		// 		display: table-cell;
		// 		text-align: center;
		// 	}
		// }

		/* ausrichtung des menus mittels justify/inline */
		// > ul {
		// 	font-size: 0 !important; /* remove physical spaces between items */
		// 	text-align: justify;
		// 	text-justify: distribute-all-lines; /* distribute items in IE */
		// 	&:after {
		// 		content: "";
		// 		display: inline-block;
		// 		width: 100%;
		// 	}
		// 	> li {
		// 		text-align: left; /* customize to suit */
		// 		vertical-align: top; /* can customize to suit */
		// 		display: inline-block;
		// 		width: 31.3%;
		// 		// float:left;
		// 	}
		// 	// > li:last-of-type::after { content: ''; padding-left: 100%; display: inline-block; }		
		// }

	}
}

/* Sub Menu - Untermenu */

#menu-sub-wrapper {
	width: 2 * @grid-full + 2 * @gutter-full;
	min-height: 2em;
	font-size:@fontsize-base * 1.5rem;
	line-height: 1.1em;
	float: left;

	a {
		text-decoration: none;
		width: 100%;
		display: inline-block;
		box-sizing: border-box;

		&:link, &:visited {
			color: @black;
			border-bottom: 1px dotted transparent;
		}

		&:hover, &:focus, &:active {
			color: @black;
			// background-color:lighten(@logo-color, 10%);
			// border-bottom: 1px dotted @black;
			.underline {
				.underline_mix(4px,5px);
			}
		}
	}

	.current > a {
		color: @logo-color;
	}

	li {
		margin-bottom: 0.5em;
	}

	.divider {
		border-bottom: 1px solid @content-color;
	}

	.nav-child {
		font-size:@fontsize-base * 0.9em;
		margin: 0.2em 0 0.5em 1em;
		li {
			margin-bottom: 0;
		}
	}
}

.bodyfrontpage #menu-sub-wrapper {
	display: none;
}

/***************** ENDE MAIN NAVIGATION *******************/
