/*
	LESS Stylesheet: Mixins
	
	GSIA / � 2014 STURM UND BR�M GMBH (www.sturmundbraem.ch) 
	
	v0.1	12.07.11 Mixins ausgelagert
	v0.2	09.08.11 Anpassungen an Button-Mixin
	v0.3	31.05.12 Anpassungen an RAL
	v0.4  	07.09.12 Anpassungen an Dialog Integration
	v0.5  	12.02.13 Anpassungen f�r JPACCART
	v0.6  	05.03.13 Anpassungen an humaneChange
	v0.7	29.11.13 Anpassungen an SOGAS
	v0.8	20.03.14 Anpassungen an GSIA
-------------------------------------------------------------------------------*/


/* ---------- START MIXINS -----------------*/

/* Icons */
.background-icon (@pos-left:0px, @pos-top:0px) {
	background-position: @pos-left @pos-top;
	background-repeat: no-repeat;
}

/* Brotschrift */
.base-textformat (@fontsize-content:@fontsize-base) {
	color:@content-color;
	line-height:@content-lineheight;
	font-size:@fontsize-content;
	font-family:@font-normal;
	font-weight:300;
	font-style:normal;
}

.base-fontbold() {
	font-family:@font-bold;
	font-weight:600;
	font-style: normal;
}

.base-fontnormal() {
	font-family:@font-normal;
	font-weight:300;
}

.base-fontdefault() {
	font-family:@font-default;
	font-weight:400;
}

.base-fontlight() {
	font-family:@font-light;
	font-weight:300;
}

.base-fontheavy() {
	font-family:@font-heavy;
	font-weight:600;
}

.base-fontsans() {
	font-family:@font-sans;
	font-weight:normal;
}

.base-fontsansbold() {
	font-family:@font-sans;
	font-weight:600;
	font-style: normal;
}

/* Hyphenation */
.hyphenation {
	-webkit-hyphens: auto;
	   -moz-hyphens: auto;
	        hyphens: auto;
}



/* Abst�nde in der Inhaltsseite */
.margin-content() {
	margin-left:@grid-content;
	margin-right:@grid-content;
}

/* Box */
.box-default(@box-color:@boxdefault-color) {
	background-color:lighten(@box-color,5%);
	margin:1em 0em 10px 0em;
	padding:0.5em 0.5em 0.5em 0.5em;
	
	
	
	p {
		color:@infobox-content-color;	
	}
	
	h3 {
		color:@infobox-header-color;
		margin:0em 0em 0.5em 0em;
	}
}
.box-noborder(@box-color:@boxdefault-color){
	.box-default;
	border:none; /* no borders */
}

/* Gradients */
.stripes(@stripe-color:lighten(@boxdefault-color,52%)) {
	background-repeat: repeat;
	background-image: url(../img/icons/gradient45.png);
	background-color: @stripe-color;
}

.content-box(@content-box-color:@box-border-color) {
	margin:2em 0em 1em 0em;
	.rounded-corners;
	border:1px solid @content-box-color;
	background-color: lighten(@content-box-color,40%);
	color:@content-box-color;
	padding:5px;
	overflow:hidden;
}

.invisible {
	display: none;
	visibility: hidden;
	position:absolute;
	top:-1000px;
	left:-1000px;
}

// von http://davidwalsh.name/html5-boilerplate
// vgl. Alternaitve : hide it like google: https://accounts.google.com/ siehe labels.
.visuallyhidden { 
	border: 0; 
	clip: rect(0 0 0 0); 
	height: 1px; 
	margin: -1px; 
	overflow: hidden; 
	padding: 0; 
	position: absolute; 
	width: 1px; 
}


.content-nav-box {
	.content-box;
}


.arrow-down(@length:0.5em,@fillcolor:#f00,@bgcolor:#FFF) {
	width: 0; 
	height: 0; 
	border-left: @length solid transparent;
	border-right: @length solid transparent;
	border-top: unit(0.866% * @length * 2, em) solid @fillcolor;
}

.arrow-right(@length:0.5em,@fillcolor:#f00,@bgcolor:#FFF) {
	width: 0; 
	height: 0; 
	border-bottom: @length solid @bgcolor;
	border-left: unit(@length * 2 * 0.866%, em) solid @fillcolor;
	border-top: @length solid @bgcolor;
}

.arrow-up(@length:0.5em,@fillcolor:#f00,@bgcolor:transparent) {
	width: 0; 
	height: 0; 
	border-right: @length solid @bgcolor;
	border-bottom: unit(@length * 2 * 0.866%, em) solid @fillcolor;
	border-left: @length solid @bgcolor;
}



/* Buttons */
.input-button {
   border:none;
	padding:0.2em 0.75em 0.2em 0.75em;
	background-color:@input-button-color;
	color:@weiss;
	font-size: @fontsize-base * 1;
	text-transform: uppercase;
	border:none !important;
	.rounded-corners(0px) !important;
}

/* Border f�r Inputs */
.input-border {
	border:none;
	background-color: @input-bgnd-color;
	box-shadow: none !important;
	padding:0.2em;
	.rounded-corners(0px) !important;
}

/* Rounded corners */
.rounded-corners (@radius: 5px) {
  border-radius: @radius;
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
}

/* Gradient */
.background-gradient(@color: @gradient-start-color, @start: @gradient-start-color, @stop: @gradient-end-color) {
  
  background-attachment: fixed !important;

  background: @color;
  background: -webkit-gradient(linear,
                               left bottom,
                               left top,
                               color-stop(0, @start),
                               color-stop(1, @stop));
  background: -ms-linear-gradient(bottom,
                                  @start,
                                  @stop);
  background: -moz-linear-gradient(center bottom,
                                   @start 0%,
                                   @stop 100%);
}

/* Focus bei Inputs */
.input-focus {
	background-image:none;

	background-color:@input-border-color;
	//color:@weiss;
}

/* INNER-SHADOW */
.inner-shadow(@horizontal:0, @vertical:2px, @blur:2px, @alpha: 0.4) {
  -webkit-box-shadow: inset @horizontal @vertical @blur rgba(0, 0, 0, @alpha);
  -moz-box-shadow: inset @horizontal @vertical @blur rgba(0, 0, 0, @alpha);
  box-shadow: inset @horizontal @vertical @blur rgba(0, 0, 0, @alpha);
}

.font-shadow(@color:#FFF, @shift:1px, @blur:1px)
{
	text-shadow: @shift @shift @blur @color;
}
	



/* BOX-SHADOW */
.box-shadow(  
        @x: 0px,  
        @y: 2px,  
        @blur: 2px,  
        @spread: 0,  
        @color: @box-border-color  
    ) {  
	-webkit-box-shadow: @x @y @blur @spread @color;  
   -moz-box-shadow: @x @y @blur @spread @color;  
    box-shadow: @x @y @blur @spread @color;  
}  


/* Boxen zentrieren */
.center(@center-width:@layout-width) {
	margin:0em auto 0em auto;
	max-width:@center-width;
}

/* Transparenz */
.transparent(@opacity:50) {
        zoom: 1;
        filter: alpha(opacity=@opacity);
        opacity: @opacity / 100;
}


/* Image Gallery */
.image-gallery-mixin (@anzahl:6, @rand:2.5%) {
	a {
		margin-bottom: @rand;
		width: ( (100% - ((@anzahl - 1) * @rand) ) / @anzahl);
		margin-right: @rand;
		display: inline-block;
		vertical-align: bottom;

		img {
			margin: 0;
		}
		// Reset
		&:nth-of-type(n) {
			margin-right: @rand;
		}
		// Letztes ohne Rand
		&:nth-of-type(@{anzahl}n) {
			margin-right: 0;
		}
	}
}


/* Underline */
.underline_mix (@stop1:2px, @stop2:3px, @shadow: white) {
	background: -webkit-linear-gradient(bottom, transparent,transparent @stop1, #000000 @stop2, transparent @stop2);
	background: -moz-linear-gradient(bottom, transparent,transparent @stop1, #000000 @stop2, transparent @stop2);
	background: -o-linear-gradient(bottom, transparent,transparent @stop1, #000000 @stop2, transparent @stop2);
	background: -ms-linear-gradient(bottom, transparent,transparent @stop1, #000000 @stop2, transparent @stop2);
	background: linear-gradient(bottom, transparent,transparent @stop1, #000000 @stop2, transparent @stop2);
	
	text-shadow: 2px 1px 0px @shadow, -2px 1px 0px @shadow, 0px -2px 0px @shadow;
}








/* ------------ ENDE MIXINS ------------------*/