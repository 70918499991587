/*
	LESS Stylesheet: Layout Small
	
	GSIA / � 2014 STURM UND BR�M GMBH
	
	v0.1	06.06.12 Erste Version
	v0.2	15.03.13 Anpassungen an JP Accart
   v0.3  11.07.13 Anpassungen an fairness at work
   v0.4		14.02.14 Anpassungen an SOGAS AG
   v0.5		24.06.14 Anpassungen an GSIA

-------------------------------------------------------------------------------*/

/* START MEDIA */
@media screen and (max-width: 833px) {
	

#bg {
	display:none;
}



body {
	z-index:0;
	font-size:@fontsize-base * 1.2;
	background-color: @weiss;
}

/****** MOBILENAV **************/
.mobilenav {
	display:block !important;
	position: fixed;
	top:0px;
	z-index:100;
	left: auto;
	right:0;
	height:2.8em;
	//background-color: red; //rgba(0,0,0,0.1);
	color:@weiss;
	width:3.2em;
	visibility: initial;
	cursor: pointer;
	padding-left: 0.4em;

	/* wird beim runterscrollen eingef�gt */
	&.burgerbg {
		background-color: rgba(0,0,0,0.1);
	}


	h2 {
		margin:8px 5% 0px 0px;
		.base-fontnormal;
		font-size: @fontsize-base * 1.7;
		text-align:center;
		// cursor:pointer;
	}
	a {
		color:lighten(@aktiv-color, 10%);
		&:hover, &:active {
			color:darken(@aktiv-color, 10%);
			text-decoration: none;
		}
	}
	&:hover {
		a {
			color:darken(@aktiv-color, 10%);
			text-decoration: none;
		}
	}
	.icon-th-menu:hover {
		&:before {
		text-decoration: none !important;
		}
	}
}

#slider {	
		background-color:@logo-color;
		z-index:105;
		position:fixed;
		color:@weiss;
		width:70%;
		top:2.5em;
		left:30%;	
		display: none;
		

		ul {
			list-style: none;

			a {
				color:@weiss;
			}

		}

	}
/* Slider ganz ausschalten, wird hier nicht gebraucht*/
#slider {
	display: block;
	height: 0;
	left: -1000px;
	overflow: hidden;
	position: absolute;
	top: -1000px;
	width: 0;
}

/******* MOBILENAV *************/

.centered {
	margin:0px 5% 0px 5%;
}

/* Logo */
#main-logo {
	//position:absolute;
	color:@logo-color;
	//top:2.2em;
	height:auto;
	max-width: 400px;
}	

.dividerlines {
	margin-top: 0.5em;
	// margin-bottom: 3em;
}




.wrapper {
	margin:0 !important;
	width:100% !important;
	z-index:0;
}

.cbp-hrmenu {
	.invisible;
}

/*********************** HEADER OF PAGE *********************/
#header-wrapper {


}

#main-header {
	position:relative;
	font-size:@fontsize-base * 1;
	background-color: @weiss;
	.base-fontdefault;
	.center;
	margin-top:0.8em;
	margin-bottom:0em;
	z-index:5;
	height:7em;
}

.bodyfrontpage {
	#main-header {
		height:auto;
	}

	// #main-logo {
	// 	svg {
	// 		fill:@black;
	// 	}
	// }
}




/**************** START PAGENAV *****************/
#content-pagenav {
	font-size:@fontsize-base * 0.7;
	margin:6em 0em 5em 0em;
	.base-fontsans;
	
	
	a {
		
		color:lighten(@content-color, 45%);
		margin:0em 0em 2em 0em;
		&:after {
			display:block;
			content:"";
		}
		&:hover {
			text-decoration: underline;
		}
	}
	
	span {
		margin-right:0em;
		color:lighten(@content-color, 45%);
		font-size: @fontsize-base * 0.8;
		display:inline;
		
	}
}

/**************** ENDE PAGENAV ****************/


.id-more-pages {
	p {
		width:100% !important;
		float:none;
	}
}




/* METANAVIGATION */
		#metanav {
			.invisible;
		}
/* ENDE METANAVIGATION */




	/******** START SUCHFUNKTION ********/
	#site-search {
		.invisible;
	}
	
	

	/******** ENDE SUCHFUNKTION ********/
	
/* HAUPTNAVIGATION nur f�r Desktop */
#menu-wrapper {
	display: none;
}
/* ENDE HAUPTNAVIGATION nur f�r Desktop */

/* UNTERNAVIGATION nur f�r Desktop */
#menu-sub-wrapper {
	display: none;
}
/* ENDE UNTERNAVIGATION nur f�r Desktop */

	
/* START MAIN IMAGE */
#main-image {
	max-width:100%;
	overflow:hidden;
	position:relative;

	img{
		width:100%;
	}
}

/* ENDE MAIN IMAGE */
	




/******************* START BREADCRUMB *******************/
#breadcrumb {
	.invisible;

}
/******************* ENDE BREADCRUMB **********************/

#content-wrapper {
	padding-top: 3em;
}

article {
	clear:both;
	float:none;
	
	h1 {
		margin:0em 0em 0.5em 0em;
		line-height: @content-lineheight - 40%;
		font-size:@fontsize-base * 1.4;
	}

	h2 {
		font-size: @fontsize-base * 1;
	}
		
	img {
		float:none;
		clear:both;
		width:100%;
	}


	ul {
		margin:0;
	}
	
}

/*************** BILDER ****************/
.page-image {
	float:none;
	clear:both;
	width:100% !important;
	margin:0 !important;
	padding:0 !important;
}

/*************** ENDE BILDER **********/

/*************** ASIDE INFOBOX **********/
#aside-container {
	margin-top: 2.5em;
}

/*************** ENDE ASIDE INFOBOX **********/

/*************** TEASER BILDER FRONTPAGE **********/
.aside-frontpage {
	margin: 1.5em 0;
	.teaser {
		figure {
			img {
				width: 100%;
				// margin-top: calc(~'-50% + 1.5em');
				// position: relative;
				float: left;
				clear: both;
				width: 20%;
				max-width: 128px;
				margin: 4px 3% 0.4em 0;

			}
		}
	}
}

/*************** ENDE TEASER BILDER FRONTPAGE **********/


/*************** START PAGINATION *****************/
.search-pagination {
	font-family: @font-sans;
	font-size:@fontsize-base;
	

}

.pagination {
	font-size:@fontsize-base * 0.9;
	font-family: @font-sans;
	
	p {
		font-family: @font-sans;	
		margin:0;
	}
	
	ul {
		border:none;
		margin:0;
		padding:0;
		li {
			margin:0px 0.5em 0px 0px;
			padding:0;
			display:block;
			float:left;
			
			&:before {
				content: "";
			}
			
		}
		
		&.pagination-list {
			margin:1em 0em 0em 0em;
			li {
				margin:0px 0.5em 0px 0px;
			}
		}

	}


}


/**************** ENDE PAGINATION ******************/



/************* START EINGANGSSEITE ***************/

.pagetype {

	&.front {
		
		.base-fontlight;
		font-size:@fontsize-base * 1;
		line-height: @content-lineheight - 50%;

		color: @content-box-color;

		

		#bubbletext {
			margin:10% 0em 10% 0em;
		}
	}

	/********* ENDE EINGANGSSEITE **********/

	/********* START ARTIKEL ************/
	&.article, &.landingpage {

		#big-image {
			max-height:300px;
			overflow:hidden;
			margin:0em 0em 2em 0em;
			background-color: @logo-color;
			width:100%;
			img {
				opacity:0.8;
				margin-top:-150px;
				width:100%;
			}	
		}

		.image-caption {
			font-size: @fontsize-base * 0.8 !important;
			margin:0.5em 0em 1.5em 0em !important;
		}


	}

	/********* ENDE ARTIKEL *************/

}


/****** Image Gallery **************/

.image_gallery {
	.image-gallery-mixin(7,2.66%);
}



/**************** LANDINGPAGE NAVIGAGION **************/
article {
	nav {
		

		&.more-pages-0 {
			margin:0em 0em 0em 0em;
			display:flex;
			
			flex-flow: row wrap;
			align-items:stretch;
			align-content:space-between;

			
			a {
				color:@weiss;
				.base-fontlight;
			}

			> ul {
				background-color: @logo-color;
				flex-basis:100%;
				padding:0.5em;

			}
		}
		&.more-pages-1 {
			margin:0em 0em 0em 0em;
			font-size:@fontsize-base * 0.8;

		}

		ul, li {
			margin:0;
			padding:0;

			&:before {
				content:"";
				margin:0;
				width:0;
			}
		}
	}
}

/************* ENDE LANDINGPAGE NAVIGATIOON **********/


.frontpage {
	.teasergroup {
		margin:2em 0em 2em 0em;

		.event-link {
			width:100%;
		}

		.teaser {
			margin:0px @gutter-content 1em 0em;
			border:1px solid @box-border-color;
			clear:both;
			float:none;
			display:flex;
			width:100%;


			.thumb {
				margin:top;
				width:100% !important;
				height:10em;
				overflow:hidden;
				float:none;
				clear:both;

				img {
					min-width:100%;
				}
			}

			.summary {
				margin:1em;

				h3 {
					margin:0;
					.base-fontbold;
					line-height: @content-lineheight - 50%;
					font-size: @fontsize-base * 1;
					color:inherit;
				}
				p {
					line-height:@content-lineheight - 40%;
					font-size: @fontsize-base * 1;
					.base-fontnormal;
					color:inherit;
				}
			}


			/* Definition Links */
			.event-link {
			
				&:link, &:visited {
					color:@aktiv-color !important;	
					text-decoration: none;
				}
			
				&:hover, &:active {
					text-decoration: underline;
					color:@link-aktiv !important;
				}	
			}


		}

	}

	
	
}


/************* ENDE EINGANGSSEITE ****************/


/******************* START FOOTER *****************/


.footer-container {
	margin-top:0em;
	padding-bottom:2em;
	// background-color: @footer-color;
	font-size: @fontsize-base * 1;
	.base-fontsans;

	
	#main-footer {
		width:90% !important;	
		padding:1em 5% 0em 5%;		
	}

	/* START FOOTERNAV */
	.idfooternav {
		width: 100%;
		color:@weiss;
		margin:1em 0em 1em 0em;
		border-top:1px solid @weiss;
		border-bottom:1px solid @weiss;
		padding:1em 0em 1em 0em;

		span {
			display:block;
			.base-fontlight;
			a {
				color:@weiss;
			}


			&.active {
				a {
					text-decoration: underline;
				}
			}

			&:before {
				margin:0em 0.5em 0em 0em;
				font-family: @font-family;
				color:@weiss;
				vertical-align: bottom;
				text-decoration: none;

			}



		}


	}
	/* END FOOTERNAV */


	/* START FOOTER-ADDRESS */
	#sga-address {
		// display: none;
		width: 100%;
		// color:@weiss;
		margin:0 !important;
		display: flex;
		flex-direction: row;
		align-items: center;
		float:none;
		clear:both;
		font-size:@fontsize-base * 0.9;
		
		#address-icon {
			margin: 0px 10% 0em 0em;
			width:50%;
			float:left;

			&.footer-logo-small {
				width: 15%;
			}

			svg {
				// fill:@black;
				max-width:100%;
				margin:0;
			}
		}

		#address-info {
			margin:0;
			// color:@weiss;
			// .base-fontlight;
			font-size:@fontsize-base * 0.9;
			line-height: @content-lineheight - 40%;
			float:left;
			width:40%;

			a {
				// color:@weiss;

			}
			.address-info-ort {
				margin-bottom: 0.5em;
			}

		}

	}
	/* Beschneidet Footer Logo auf Icon, funzt noch nicht */
	.footer-logo .logo-text {
		// display: none !important;
	}

	/* ENDE FOOTER-ADDRESS */
	
	
	
	
	/* START FOOTER-SITEMAP */
	#footer-sitemap {
		font-size:@fontsize-base * 1;
		width: 90%;
		margin-bottom:4em;
		
		.menu {
			padding:0;
			margin:0;
			
			a {
				color:@content-color;
				line-height: @content-lineheight - 0%;

				&:link, &:visited {
					text-decoration:none;
				}
				
				&:hover, &:active {
					text-decoration:underline;
				}	

			}


			
			.active {
				> 	a {
					text-decoration:underline;
				}
			}
			
			> li {
				display:block;
				width: 100%;
			
				overflow:hidden;
				padding:0;
				margin:2.5em 0em 0em 0em;
				line-height: @content-lineheight - 40%;
				.base-fontsansbold;

				&:first-of-type {
					margin-top: 0;
				}

			 	&:last-child {
			 		margin-right:0;
			 }
				

				
				
			> a {
				text-decoration:none;
					
					
			}
				
			ul {
				margin:0;
				padding:0;
				
				
				li {
					margin:0em 0em 0em 0em;
					padding:0px 0px 0px 0px;
					.base-fontsans;
					font-size:@fontsize-base * 1;
					line-height: @content-lineheight - 45%;
					
					&.active {
						> a {
							text-decoration: underline;	
						}
					
					}
					
				}

				ul {
					margin: 0 0 0 1.5em;
				}
			}
			
		}
	}

		#bottom-pagenav {
			color:@weiss;
			a {
				color:@weiss;
			}
		}
	}
	
	/* ENDE FOOTER-SITEMAP */
	
}


/******************* ENDE FOOTER *****************/

/************** START EVENT BOOKING ***************/
.eb-thumb-left {
	
}

.eb-container {
	h1 {
		margin:1em 0em 0.5em 0em !important;
	}

	h2 {
		margin:2em 0em 1em 0em !important;
	}
}

.eb_notice, .eb-notice-table {
	
	
}

#eb-events, #eb-event-details {



	.eb-description-details {
		table {
			
			tr {
				td {
					width:50%;
					font-size:@fontsize-base * 0.8;
				}
			}
		}
	}

	.eb-taskbar {
		margin:0em 0em 2em 0em;
	}

	ul {
		li {
			&:before {
				content:"";
			}
		}
	}
}

/*************** ENDE EVENT BOOKING ***************/

/*************** TABULIZR ***************/
.responsive-reflow td:before {
	display: block;
	}
/*************** ENDE TABULIZR ***************/

/*************** MEMBER LOGIN ***************/
.login form .row label {
	width: 45%;
	}



.login form .row div.checkbox input.checkbox {
	// width: 6%;
	// margin-left: 39%;
	width: 6%;
	margin-left: 39%;
	margin-right: 2%;
	text-align: right;
	display: block;
	float: left;
	}
.login form .row div.checkbox label.checkbox {
	text-align: left;
	float: right;
	width: 52%;
	margin: 0; 
	}
.login form .row button.button {
	margin-left: 47%;
}
/*************** ENDE MEMBER LOGIN ***************/

/*************** KONTAKT FORMULAR***************/
form#chronoform_kontakt {
	> p {
		font-size: @fontsize-base * 0.9;
	}
	.row {
		.checkbox {
			#kontakt_kontaktieren {
				margin-left: 2%;
				margin-right: 10%;
				float: right;	
			}
			label.checkbox {
				text-align: right;
				float: right;
				width: 80%;
				margin-right: 0;
				margin-top: 0;
			}
		}
		&.captcha, &.captcha-input {
			img {
				clear: none;
				float: left;
				margin-right: 0;
			}
			label {
				width: 62%;
				margin-top: 0;
			}
		}
	}
}


/*************** ENDE KONTAKT FORMULAR***************/





}
 /* FIN MEDIA */






