/*
	LESS Stylesheet: Layout XX-Small
	
	SGA / © 2014 STURM UND BRÄM GMBH

	Für Korrekturen bei sehr kleinen Bildschirmen,
	die hauptsächliche Gestaltung läuft über layout-small.less
	
	v0.1	06.11.14 Erste Version

-------------------------------------------------------------------------------*/

/*---------------------------------------- START MEDIA MAX-WIDTH 600 */
 @media screen and (min-width: 1px) and (max-width: 600px) {

 	/****** FOOTER LOGO **************/
 	.footer-container #sga-address #address-icon.footer-logo-small {
 	width: 20%;
 	}
 	
}

/*---------------------------------------- FIN MEDIA MAX-WIDTH 600 */



/*---------------------------------------- START MEDIA  MAX-WIDTH 480 */
@media screen and (max-width: 480px) {
	

	/****** Image Gallery **************/

	.image_gallery {
			.image-gallery-mixin;
	}

	/****** Fancy Box Override **************/




	/****** KONTAKTFORMULAR **************/

	form#chronoform_kontakt {
		.row {
			.requiredtext {
				.fc-error {
					p{
						width: 100%;
						margin: 0;
						display: block;
					}
				}
			}
		}
	}



	/* START FOOTER-ADDRESS */
	.footer-container {
		#sga-address {
			font-size:@fontsize-base * 0.8;
		}
	}


	/********** PAGE VORSTAND ************/

	.vorstandsmitglied {
		min-width: 267px;
	}





}
/*---------------------------------------- FIN MEDIA MAX-WIDTH 480 */


/*---------------------------------------- START MEDIA  MAX-WIDTH 40em */
/* Tabulizr Template Overwrite */
 @media screen and (max-width: 40em) {

 	article table tr td.stubr-col-wide, 
 	article table tr td.stubr-col-small, 
 	article table tr td.stubr-col-w1, 
 	.responsive-reflow td {
 	width: 100%;
 	box-sizing: border-box;
 	}

 	article table tr td.stubr-col-s1 {
 		display: inline-block;
 		// display: table-cell;
 	}

 	table tr.tabrow {
 		border: 0;
 		border-top: 1px dotted lighten(@black,40%);
 		&:last-of-type {
 			border-bottom: 1px dotted lighten(@black,40%);
 		}
 		// &.even {
 		// 	background-color: lighten(@black,95%);
 		// }

 	}


 	// stubr_default ist spezifisch für die Kollektivmitglieder Tabelle auswählen:
	.tabtable-rs_stubr_default {
		td[data-title="PLZ"] {
	    display: none;
		}
		
		&.responsive-reflow {
		// Start Kategorien ausblenden
			tr {
				td {
			 		&:before {
			 			// fette Sans Titel
				 		// .base-fontsansbold;
				 		// font-size: 0.8em;

				 	// keine Titel anzeigen
				 		display: none;
			 		}
			 		&:first-of-type {
			 			font-weight: 600;
			 		}
			 		border: 0;
			 		padding-bottom: 0;
			 		&:last-of-type {
			 			padding-bottom: 0.4em;
			 		}
			 	}
			 	padding: 0.4em 0 0.4em 0;
			}
		// End Kategorien ausblenden
		}
	}

	// Kollektivmitglieder Tabelle: nur sortierbare Header als Liste anzeigen
	.tabtable-rs_stubr_default.responsive-reflow, .responsive-reflow {
		thead tr {
			position: static;
			top: auto;
			left: auto;
			margin-bottom: 1em;
			border: 0;
			&:last-of-type {
				border: 0;
			}
			td {
				border: 0;
				&.sorting_disabled {
					position: absolute;
					top: -9999px;
					left: -9999px;
				}
				padding-bottom: .8em;
				padding-right: 2em;
				width: auto;
				position: static;
				display: inline-block;
			}
		}
	}

 	// stubr_akustiker ist spezifisch für die Dipl.-Akustiker-Tabelle:
	.tabtable-rs_stubr_akustiker {
		// td[data-title*="Name"] {
	 //    display: none;
		// }
		
		&.responsive-reflow {
		// Start Kategorien ausblenden
			tr {
				td {
					// display: table-cell;
					border: 0;



			 		&.stubr-col-s1 {
			 			width: 11.11%;
			 			min-height: 50px;
			 			border-left: 1px solid @content-color;
			 			padding: 0 0.3em;
			 			margin-top: 0.8em;
			 			text-align: center;
			 			&:before {
			 				// margin: 0;
			 				// padding: 0;
			 				.base-fontsansbold;
			 				font-size: 0.9em;
			 				width: 100%;
			 				text-align: center;
			 			}
			 			&:last-of-type {
			 				border-right: 1px solid @content-color;
			 			}
			 		}
			 		&.sorting, &.sorting_asc, &.sorting_desc {
			 			padding-left: 3px;
			 			margin: 0;
			 			padding: 0.3em;
			 			padding-top: 23px;
			 			background-position: 50% 3px;
			 		}
			 		&[aria-label*="Name"] {
			 			&.sorting, &.sorting_asc, &.sorting_desc {
			 				background-position: 0px 3px;
			 			}
			 		}
			 		// &:first-of-type {
			 		// 	font-weight: 600;
			 		// }
			 		// border: 0;
			 		padding: 0;
			 		// &:last-of-type {
			 		// 	padding-bottom: 0.4em;
			 		// }


 			 		&[data-title*="Name"], &[aria-label*="Name"] {
 			 			display: block;
 			 			&:before {
 			 			// fette Sans Titel
 				 		// .base-fontsansbold;
 				 		// font-size: 0.8em;

 				 		// keine Titel anzeigen
 				 		display: none;
 			 			}
 			 			&:first-line {
 			 				font-weight: 600;
 			 			}
 			 		}
 			 		// truncate Titel
 			 		&[aria-label*="Name"] {
 			 			white-space: nowrap;
 		 			    overflow: hidden;
 		 			    text-overflow: ellipsis;
 		 			    height: 50px;
 		 			    margin-bottom: 1em;

 		 			    color: white;
 		 			    &:first-line {
 		 			    	color: @content-color;
 		 			    }

 			 		}

			 	}
			 	padding: 0.8em 0 1em 0;
			}
		// End Kategorien ausblenden
		}
	}
	
}



/*---------------------------------------- FIN MEDIA  MAX-WIDTH 40em */
