/*
	LESS Stylesheet: Print
	
	SOGAS AG / � 2014 STURM UND BR�M GMBH
	
	v0.1	30.05.13 Erste Version
	v0.2  05.08.13 Anpassungen an fairness-at-work
	v0.3	06.03.14 Anpassungen an SOGAS AG
-------------------------------------------------------------------------------*/

/* START MEDIA */
@media print {
	


/**************************** BODY *****************************************/
body {
	font-size:@fontsize-base * 0.8;

}



/**************** Print *******************/
	#metanav, #bg, .id-more-pages,.hidden, .mobilenav, #slider, #infocol, #breadcrumb,.menu-caption-wrapper, .drawer, .cbp-hrmenu, #news-teaser, .idfrontpageteaser, .idlandingpageteaser, .top, .menu, #ident, form, .spshare, #content-pagenav, #info-box {
		display: none;
	}
	
	#main-logo {
		position:relative;
		top:1cm;
		left:2.5cm;
		height:4cm;

		.icon-sogas-logo {
			font-size:@fontsize-base * 5.5;
			color:@weiss;
		}

	}
	
	#addon-box {
		a:after {
			content:"";
		}
	}

	#claim {
		background-color:@weiss;
		width:2.5*@grid-full + 2*@gutter-full;
		color:@weiss;
		.base-fontbold;
		letter-spacing: 0.1em;
		position:absolute;
		right:2cm;
		top:6.5em;
		text-align: right;
		padding:0 3px 2px 0;
		font-size:@fontsize-base * 0.9;
		height:1.45em;
	}
	
	


	article {
		width:80%;
		margin-left:12.5%;
		h1 {
			font-size:@fontsize-base * 1.5;
		}
	}

	footer {
		border-top:2px dotted @content-color;
		margin-top:1cm;
	}
	
	#sogas-address {
		display:block;
		.base-fontlight;
		position:relative;
		left:12.5%;
		top:0.2cm;
		font-size:@fontsize-base * 1.2;
	}
	

	


}
/* FIN MEDIA */