/*
	LESS Stylesheet: Layout Wide
	
	GSIA / © 2013/14 STURM UND BRÄM GMBH
	
	v0.1	06.06.12 Erste Version
	v0.6  	12.09.13 Anpassungen an GSIA
	v0.7	20.03.14 Anpassungen an GSIA
	
-------------------------------------------------------------------------------*/

/* START MEDIA */
@media screen and (min-width: 834px) {
	

// jetzt 
#slider, .hide-wide {
	.invisible();
}


body {

}


/************************ WRAPPER *******************/
.wrapper {
	width:100%;
	margin:0;
}

/************************ ENDE WRAPPER **************/

/********************* CENTERED *****************************/
.centered {
	.center;
	position:relative;
}

/********************* ENDE CENTERED ************************/



/*********************** HEADER OF PAGE *********************/


#main-header {
	// position:relative;
	// top:0px;
	// height:197px;
	// width:100%;
	font-size:@fontsize-base * 1;
	.base-fontnormal;
	z-index:70;	
	
		
	
	/* Logo */
	#main-logo {
		// position:absolute;
		// color:@logo-color;
		// top:47px;
		// left:6.5625%;
		// width:8 * @grid-full + @gutter-full;
		width: 544px;
		height: 165px;
		padding-top: 43px;

		// svg {	
		// 	fill:@black;			}
		
	}	
	
}
/*********************** ENDE HEADER OF PAGE ******************/


/**************** DRAWER *************************/
.drawer {
	// background-color: @logo-color;
	width:100%;
	height: 32px;
	margin-top: 48px;
}

/***************** ENDE DRAWER *******************/

/***************** MAIN NAVIGATION *******************/
/* Ausschalten Mobile-Hilfsnavigation */
.top {
	display:none;
	visibility:hidden;
	position:absolute;
	left:-1000px;
	top:-1000px;
}

/***************** ENDE MAIN NAVIGATION *******************/




/* METANAVIGATION */
		#metanav {
			position: absolute;
			font-size: @fontsize-base * 1.05;
			width: 100% - @aussenabstand;
			height: auto;
			top: 0.5em;
			right: 0;
			z-index: 70;
			text-align: right;

			/* Hide Dropdowns from being present */
			// .dropdown {
			// 	display:none;
			// 	position:absolute;
			// 	right:0px;
			// 	z-index:70;
			// 	background-color:@aktiv-color;
			// 	top:1.5em;
			// 	padding: 0.5em;
			// 	color:@weiss;
			// 	width: 5 * @grid-full + 5 * @gutter-full;
			// 	.base-fontlight;


			// 	&.search {
			// 		.search-query {
			// 			width:80%;
			// 		}
			// 	}

			// 	a {

					
			// 		font-size:@fontsize-base * 1;
			// 		vertical-align:top;
			// 		color:@weiss;
			// 		font-weight: 300 !important;
			// 	}

			// 	li, p {
			// 		text-align: left;
			// 		margin:0px 0.5em 0px 0.5em;

			// 	}

			// 	li:before {
		 //  			content: "\e61e";
		 //  			font-family: @font-family; /* or whatever */
		 //  			display: inline-block;
		 //  			margin-left: -1.2em; /*same as padding-left set on li*/
		 //  			width: 1.2em; /*same as padding-left set on li*/
		 //  			font-weight: 300 !important;
			// 	}

			// 	.button {
			// 		background-color: lighten(@okay-color,0%);
			// 	}

			// 	label {
			// 		font-weight: 300 !important;
			// 	}

			// 	input.text:focus {
			// 		background-color: @weiss;
			// 		color:@content-color;
			// 	}

			// }
			.css-arrow-down{
				display:inline-block;
				height:1em;
				margin-left:1em;	
				margin-top: 0.5em;
				.arrow-down(0.25em,lighten(@content-color, 30%));
			}

			// .dropdown-anchor {

			// }

			display: flex;
			justify-content: flex-end;
			flex-wrap: wrap;


			ul {
				display: flex;

				li {
					display: inline;
					padding: 0; // 0.35em;
					margin: 0;
				}
				li:last-of-type {
					// border-right: 1px solid black;
				}
			}
	
			a {
				padding: 0 0.4em;
				.base-fontsans;
				font-size:@fontsize-base * 0.9;
				vertical-align:top;
				color:@black;
				text-decoration: none;

				&:link, &:visited {
					color: @black;
				}
				
				&:hover, &:focus, &:active {
					color: @logo-color;
				}
			}
			
			// Abstand zwischen den Menu-Einträgen
			span, ul li {
				margin-left: 0.5em;
			}

			// Abstand zwischen Sprachauswahl und Quicklinks (sollten gleich sein)
			.idlanguage {
				padding-right: 1em;
			}
			span:first-of-type {
				margin-left: 1em;
			}

			// Trennstrich zwischen Sprachauswahl und Quicklinks
			.idlanguage {
				border-right: 1px solid black;
			}

			.divider, .separator {
				margin: 0.5em 0 0 0;
				height: 0;
				border: 0;
				width: 100%;
			}
				
		}
/* ENDE METANAVIGATION */

/* START MAIN IMAGE */
// #main-image {
// 	max-width:100%;
// 	overflow:hidden;

// 	img{
// 		width:100%;
// 	}
// }

/* ENDE MAIN IMAGE */

.dividerlines {
	margin-bottom: 88px;
}
.footer-container .dividerlines {
	margin-bottom: 0px;
}

/**************** LANDINGPAGE NAVIGAGION **************/
article {
	nav {
		

		&.more-pages-0 {
			margin:0em 0em 0em 0em;
			display:flex;
			
			flex-flow: row wrap;
			align-items:stretch;
			align-content:space-between;

			
			a {
				color:@weiss;
				.base-fontlight;
			}

			> ul {
				background-color: @logo-color;
				flex-basis:28%;
				margin: 0em auto 0.8em 0em;
				padding:0.5em;

			}
		}
		&.more-pages-1 {
			margin:0em 0em 0em 0em;
			font-size:@fontsize-base * 0.8;

		}

		ul, li {
			margin:0;
			padding:0;

			&:before {
				content:"";
				margin:0;
				width:0;
			}
		}
	}
}

/************* ENDE LANDINGPAGE NAVIGATIOON **********/
	

/*************** START INFO-BOX *******************/
#infocol {
	width:@width-addon;
	float:left;
	position:relative;
	top:0em;


	.infobox-wrapper {
		.rounded-corners;
		.box-default(@infobox-color);
		padding-bottom:2em;

		h3 {
			.base-fontnormal;
			font-size: @fontsize-base * 1.4;
			margin:0.5em 0em 1em 0em;
			padding:0em 0em 0.2em 0em;
			border-bottom:1px dotted @content-color;
		}

		.icon-flash {
			margin-top:0.2em;
			text-align: center;
			float:left;
			width:20%;
			font-size:@fontsize-base * 2;
		}
		.pikett-address {
			float:left;
			width:80%;
		
			h3 {
				.base-fontbold;
				font-size:@fontsize-base * 1;
				margin:0;
				border:none;
			}

			p {
				margin:0;

				&.telnr {
					.base-fontbold;
					margin-top:1em;
				}

				&.description {
					font-size:@fontsize-base * 0.8;
				}
			}

		}

		.readmore{
			font-size: @fontsize-base * 0.8;
			margin-left:20%;
		}

		.idinfobox:nth-child(2) {
			border-top:1px dotted @content-color;
			padding-top:0.8em;
			padding-bottom:1em;
		}

		.more-pages-0 {
			p {
				margin:0em 0em 0em 20%;
				font-size: @fontsize-base * 0.8;
				.base-fontnormal;
			}
		}

	}

	
	
}
/*********** ENDE INFO-BOX **************/

/******************* START BREADCRUMB *******************/
#breadcrumb {
	font-size:@fontsize-base * 1;
	line-height:@content-lineheight - 50%;
	.base-fontlight;
	margin-top:2em;
	
	.breadcrumb-separator {
		color:@light-content-color;
		font-size:@fontsize-base * 0.9;
		.icon-arrow-right-thick {
			margin-left:2px;
			margin-right:2px;
		}

	}
}
/******************* ENDE BREADCRUMB **********************/



/*************** START PAGINATION *****************/
.search-pagination {
	font-family: @font-sans;
	font-size:@fontsize-base;
	

}

.pagination {
	font-size:@fontsize-base * 0.9;
	font-family: @font-sans;
	
	p {
		font-family: @font-sans;	
		margin:0;
	}
	
	ul {
		border:none;
		margin:0;
		padding:0;
		li {
			margin:0px 0.5em 0px 0px;
			padding:0;
			display:block;
			float:left;
			
			&:before {
				content: "";
			}
			
		}

		&.pagination-list {
			margin:1em 0em 0em 0em;
			li {
				margin:0px 0.5em 0px 0px;
			}
		}
		
	}
}


/**************** ENDE PAGINATION ******************/

/**************** START PAGENAV *****************/
#content-pagenav {
	font-size:@fontsize-base * 0.8;
	margin:6em 0em 5em 0em;
	.base-fontsans;
	
	
	a {
		margin-right:2em;
		color:lighten(@content-color, 45%);
		&:hover {
			text-decoration: underline;
		}
	}
	
	span {
		margin-right:0.2em;
		color:lighten(@content-color, 45%);
		font-size: @fontsize-base * 0.9;
		display:inline !important;
		
	}
}

/**************** ENDE PAGENAV ****************/


/***************** START CONTENT *****************************/
#content-container {

	/* Box mit Margin - funzt nicht mit Formularen, wegen "row clearfix" dort */
	// width: 5 * @grid-full + 4 * @gutter-full;
	// margin-left:3 * @gutter-full + 3 * @grid-full;

	/* Box mit padding & inline-block */
	width: 5 * @grid-full + 4 * @gutter-full;
	padding-left:1 * @gutter-full + 1 * @grid-full;
	box-sizing: content-box;
	display: inline-block;
	
	#content-box {
		width: 100%;

	}	
}

/* Frontpage */
.bodyfrontpage #content-container {
	width: 100%;
	margin-left: 0;
	padding-left: 0;

	article#content {
		width: 4 * @grid-full + 3 * @gutter-full;
		float: left;
		margin-right: 1 * @grid-full + 2 * @gutter-full;
	}
}


/***************** ENDE CONTENT *****************************/



/************* SEITENTYPEN **************/
#content-box {


	article {
		// width: 6 * @grid-content +  @gutter-content;
	
		.item-page, .leading-0 {
			h1 {
				margin-top:0em;
			}
		}
	}
}



/************* START EINGANGSSEITE ***************/

.pagetype {

	&.front {
		position:absolute;
		top:-12em;
		left: 2 * @grid-full + 2 * @gutter-full;
		width: 4 * @grid-full + @gutter-full;
		.base-fontlight;
		font-size:@fontsize-base;
		line-height: @content-lineheight - 30%;
		background-color: @weiss;
		color: @content-box-color;

		.css-arrow-up {
			.arrow-up(0.8em, @weiss);
			position:absolute;
			top:-0.8em;
			left:@gutter-full;
		}

		#bubbletext {
			margin:5%;
		}
	}

	/********* ENDE EINGANGSSEITE **********/

	/********* START ARTIKEL ************/
	&.article, &.landingpage {

		#big-image {
			max-height:300px;
			overflow:hidden;
			margin:0em 0em 2em -1em;
			background-color: @logo-color;
			width:105%;
			img {
				opacity:0.8;
				margin-top:-150px;
				width:100%;
			}	
		}


	}

	/********* ENDE ARTIKEL *************/

	/********** START ORGANISATIONS *********/
	&.organisation {
		.logo-organisation {
			width:30%;
			img {

			}
		}
	}

	/********** ENDE ORGANISATIONS ***********/

	/************ START BOARD ************************/
	&.board {
		.adr {
			margin:0em 0em 1em 0em;
		}
		.col1 {
			display:inline-block;
			width:15%;
		}
	}

	/************ ENDE BOARD ******************/

}
.frontpage {
	.teasergroup {
		margin:2em 0em 2em 0em;
		flex-flow: wrap;
		justify-content:flex-start;
		display:flex;


		.teaser {
			width: 32%;
			margin-bottom:1em;
			margin-right:1%;
			border:1px solid @box-border-color;



			.thumb {
				margin:top;
				max-height:107px;
				overflow:hidden;
			}

			.summary {
				margin:1em;

				h3 {
					margin:0;
					.base-fontbold;
					line-height: @content-lineheight - 50%;
					font-size: @fontsize-base * 0.8;
					color:inherit;
				}
				p {
					line-height:@content-lineheight - 40%;
					font-size: @fontsize-base * 0.8;
					.base-fontnormal;
					color:inherit;
				}
			}


			/* Definition Links */
			.event-link {
			
				&:link, &:visited {
					color:@aktiv-color !important;	
					text-decoration: none;
				}
			
				&:hover, &:active {
					text-decoration: underline;
					color:@link-aktiv !important;
				}	
			}


		}

	}

	
	
}


/************* ENDE EINGANGSSEITE ****************/

/************** START EVENT BOOKING ***************/
.eb-thumb-left {
	max-width:30% !important;
}

.eb-container {
	h1 {
		margin:1em 0em 0.5em 0em !important;
	}

	h2 {
		margin:2em 0em 1em 0em !important;
	}
}

.eb_notice, .eb-notice-table {
	.content-box(@hint-color);
	
}

#eb-events, #eb-event-details {



	.eb-description-details {
		table {
			width:auto !important;
			tr {
				td {
					font-size:@fontsize-base * 0.8;
				}
			}
		}
	}

	.eb-taskbar {
		margin:0em 0em 2em 0em;
	}

	ul {
		li {
			&:before {
				content:"";
			}
		}
	}
}

/*************** ENDE EVENT BOOKING ***************/



/************ START LANDINGPAGE *******************/
/* Bigimage wide */
#big-image-wide {
	background-color:lighten(@logo-color,30%);
	height:300px;
	width:100%;
	position:absolute;
	z-index:0;
}

.landingpage {


	
	.introduction {
		.base-fontlight;
		font-size:@fontsize-base * 1.2;
		line-height:@content-lineheight - 40%;
	}

	

}


/************* START FRONTPAGE INTRO **************/
.frontpage {
	.idfrontpageteaser {
		clear:both;
		float:none;
		margin:2em 0em 4em 0em;
	
		
		.blog {
			
			.items-row {
				float:left;
				width:3 * (@gutter-frontpage + @grid-frontpage) + @grid-frontpage;
				margin-right: @gutter-frontpage;
				margin-bottom:2em;
				
				.frontpageteaser {
				
	
					&.icon {
						float:left;
						width:48%;
						height:116px;
						overflow:hidden;
						margin-right:2%;
						font-size:@fontsize-base * 6;
						text-align: center;	
						
						
					
					}
					
	

					
					&.text {
						width:50%;
						float:left;
					}
					
				}
				
				
				a {
					color:@content-color;
				}
		
			&:nth-child(3n) {
				margin-right:0px;
			}	
		
			}
		}
	
		
	}

}


/************** ENDE FRONTPAGE INTRO ************/



/************* START LANDINGPAGE INTRO **************/
.landingpage {
	.idlandingpageteaser {
		clear:both;
		float:none;
		margin:2em 0em 4em 0em;
	
		
		.blog {
			
			.items-row {
				float:left;
				width:2 * (@gutter-frontpage + @grid-frontpage) + @grid-frontpage;
				margin-right: 2 * @gutter-frontpage + @grid-frontpage;
				margin-bottom:2em;
		
			&:nth-child(3n) {
				margin-right:0px;
			}	
		
			}
		}
	
		
	}

}


/************** ENDE LANDINGPAGE INTRO ************/



/*********** ENDE LANDINGPAGE **********************/

/************** START SEARCH RESULTS ************/
article {
	.finder {
		#search-form {
			#finder-search {
				.icon-search {
					font-size:@fontsize-base * 0.8;
				}
			

			
				input, .button {
					.input-border;
					.base-fontnormal;
	
					display:block;
					float:left;
			
					
					&:focus, &:hover {
						.input-focus;	
					}
				}
				input {
					margin-right:1%;
					color:@ghosted-color;
				}
			
				.button {
					.input-button;
					color:@weiss;
					background-color: @aktiv-color;
					
					
				}
					
				}
			
		}
	}
}


/*************** ENDE SEARCH RESULTS ***********/

/************** START NEWS ************/
.blog-news {
	.partner-item {
		margin-bottom:3em;
	}

	.small-image {
		float:left;
		width:2 * @grid-content + @gutter-content;
		margin-right:@gutter-content;
		height:116px;
	
	}
	.page-partner-link {
		margin-top:2em;
		.icon-link {
			margin-right:0.2em;
		}
	}
}


/************** ENDE NEWS **********/





/************* ENDE SEITENTYPES ************/

/********* START ASIDE / TEASER AUF FRONTPAGE ***********/
.aside-frontpage {
	// width: 3 * @grid-full + 2 * @gutter-full;
	width: @width-frontpage-aside-full;
	float: right;

	.teaser {
		margin: 0 0 1em 0;
		figure  {
			img {
				width: 1 * @grid-frontpage-aside-full;
				margin: 3px 1em 1em 0;
				float: left;
				margin-right: 1.5 * @gutter-frontpage-aside-full;
			}
		}
		.summary {
			margin-bottom: 1em;
		}
	}
}


/********* START ASIDE / TEASER AUF FRONTPAGE ***********/

/********* START TEASER AUF JEDER SEITE ***********/
#news-teaser {
	margin:2em 0em 2em 0em;
	

	.idblogteaser, .idnewsteaser, .idstellenteaser {
		width: 3 * (@grid-frontpage + @gutter-frontpage) + @grid-frontpage;
		margin-right:@gutter-frontpage;
		float:left;
		
		
		
		#blogteaser-wrap, #newsteaser-wrap, #stellenteaser-wrap {
			float:left;
			width: @grid-3col + @gutter-3col;
			margin-right:@gutter-3col;
		
			height:auto;
		
			div {
				font-size:@fontsize-base * 4;
				height:63px;
				overflow:hidden;
			}
			h3 {
				margin:0;
				padding:2% 0em 2% 2%;
				.base-fontbold;
				color:@weiss;
				width:98%;
				font-size:@fontsize-base * 0.8;
				text-transform: uppercase;
				line-height: @content-lineheight - 60%;
			}
		
		}
		
		#newsteaser-wrap, #stellenteaser-wrap {
			div {
				font-size:@fontsize-base * 3.5;
			}
			h3 {
				
			}
		}
		
		
		
		
		.blog {
			.base-fontlight;
			line-height: @content-lineheight - 50%;
			font-size:@fontsize-base * 1;
			margin-left:@gutter-3col;
		}
		
	}
	
	> div:nth-child(2n) {
		margin-right:0px;
	}	
	
	

}





/********* ENDE TEASER AUF JEDER SEITE ************/

/********* START ASIDE INFOBOX ************/
#aside-container {
	float: right;
	//width: 2.5 * @grid + 2 * @gutter;
	width: 2.5 * @grid-content + 2 * @gutter-content;
	margin-left: 0.5 * @grid-content + 0 * @gutter-content;
	.document-description {
	}
}


/********* ENDE ASIDE INFOBOX ************/

/********** DOWNLOAD-BOX ************/
.page-download {
	margin:1em 0em 2em 0em;
	h3 {
		margin:1em 0em 0em 0em;
	}
	.document-item {
		margin:0em 0em 1em 0em;
		border-bottom:1px solid lighten(@content-color,60%);

		.icon-file-pdf {
			font-size:@fontsize-base * 1.2;
			margin-right:-0.3em;
		}

		.document-description {
			.base-fontlight;
		}
	}
}



/*********** ENDE DOWNLOAD-BOX *****/

/******************* START FOOTER *****************/


.footer-container {
	margin-top: 4em;
	padding-bottom:8em;
	background-color: @footer-color;

	
	#main-footer {
		width:100%;	
		padding-top:0em;		
	}

	/* START FOOTERNAV */
	.idfooternav {
		width: 100%;
		color:@weiss;


		span {
			margin-right:2em;
			float:right;

			a {
				color:@weiss;
			}


			&.active {
				a {
					text-decoration: underline;
				}
			}

			&:before {
				margin:0em 0.5em 0em 0em;
				font-family: @font-family;
				color:@weiss;
				vertical-align: bottom;
				text-decoration: none;

			}



		}


	}
	/* END FOOTERNAV */


	/* START FOOTER-ADDRESS */
	#sga-address {
		width: 2 * @grid-full + 1 * @gutter-full;
		margin:0em 9.62306994% 0em @aussenabstand;
		float:right;
		
		#address-icon {
			// margin-top:25px;
			object svg.main-logo {
				max-width:100%;
				margin:0;
				width: 278px;
				height: 84px;
			}
		}
	}

	/* ENDE FOOTER-ADDRESS */
	
	
	

	#footer-sitemap {
		width: 4 * @grid-full + 3 * @gutter-full;
		margin: 25px 0 0 0;

		float:right;
		ul.menu {
			// width: 100%;
			height: 350px;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;

			ul {
				// display: inline;
				// width: 2 * @grid;
				margin-bottom: 1em;
				ul {
					display: none;
					margin: 0 0 0 1.5em;
				}
			}
			> li {
				// width: 50%;
				width: 2 * @grid-footer-flexbox + 1 * @gutter-footer-flexbox;
				margin-right: 1 * @gutter-footer-flexbox;
				// width: 2 * @grid;
				// margin-right: 1 * @gutter;
			}
		}
	}
	/* ENDE FOOTER-SITEMAP */

	/* FOOTER-METANAV */
	#metanav-footer {
		width: 1 * @grid-full;
		float: right;
		margin-top: 25px;

		ul {
			li {
				
			}
		}

		ul li, span[class^=item] {
			// margin-bottom: 10px;
		}
	}
	/* ENDE FOOTER-METANAV */



	
	
}



.no-flexbox {
	#footer-sitemap{
		.nav {
			height:auto !important;
		}
	}
}

/******************* ENDE FOOTER *****************/


/************************ SEITENABSTÄNDE *******************/

// .centered, #content-wrapper, #main-footer, #metanav {
// 	// background-color: lighten(blue, 20%) !important;
// 	padding-left: 1.5em;
// 	padding-right: 1.5em;
// 	box-sizing: border-box;
// }



#menu-wrapper nav.top-menu, #content-inner-wrapper {
	margin-left: @aussenabstand;
	margin-right: @aussenabstand;
}
#main-logo, #sga-address {
	margin-left: @aussenabstand;
}
#metanav-footer {
	margin-right: @aussenabstand;
}
#metanav {
	right: @aussenabstand;
}



/************************ ENDE SEITENABSTÄNDE **************/




}
/* FIN MEDIA */



